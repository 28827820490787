import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import * as assetsSlice from '../assetsSlice'

import { SortIconsByEnum } from '../types'

function SortIcons() {
  const dispatch = useDispatch()
  const sortIconsBy = useSelector(assetsSlice.selectSortIconsBy)

  return (
    <select
      onChange={(e) =>
        dispatch(assetsSlice.actions.setSortIconsBy(e.target.value as SortIconsByEnum))
      }
      value={sortIconsBy}
    >
      <option value={SortIconsByEnum.NAME}>Sort by name</option>
      <option value={SortIconsByEnum.SVG_SIZE}>Sort by current SVG size</option>
      <option value={SortIconsByEnum.ORIGINAL_SVG_SIZE}>Sort by original SVG size</option>
      <option value={SortIconsByEnum.COMPILATION_SVG_SIZE_CHANGE}>
        Sort by compilation SVG size change %
      </option>
      <option value={SortIconsByEnum.LAST_DOWNLOAD_SVG_SIZE}>
        Sort by last download (runtime) size
      </option>
      <option value={SortIconsByEnum.RUNTIME_SVG_SIZE_CHANGE}>
        Sort by runtime SVG size change %
      </option>
    </select>
  )
}

export default SortIcons
