import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Col, Collapse, Input, Row, Tooltip } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../app/api/apiHook'
import { FeedbackMessageType } from '../../../../app/types'
import AntFormItemExplain from '../../../../components/AntFormItemExplain/AntFormItemExplain'
import * as assetsSlice from '../../assetsSlice'
import IconsRemoveModal from '../../icons-remove-modal/IconsRemoveModal'
import IconItemUpload from './icon-item-upload/IconItemUpload'
import styles from './IconItem.module.scss'
import TagsSection from './tags-section/TagsSection'
import SizeDisplay from './SizeDisplay'
import { Icon } from '../../types'

interface IconItemProps {
  icon: Icon
}

/**
 * @todo make flex size of the column with the icon preview dynamically
 * change with the preview size
 */
const IconItem: FunctionComponent<IconItemProps> = ({ icon: iconInitialValue }) => {
  const showTags = useSelector(assetsSlice.selectShowTags)
  const selectedIcons = useSelector(assetsSlice.selectSelectedIcons)
  const family = useSelector(assetsSlice.selectFamily)
  const category = useSelector(assetsSlice.selectCategory)
  const subcategory = useSelector(assetsSlice.selectSubcategory)

  const [icon, setIcon] = useState(iconInitialValue)
  const [nameInputValue, setNameInputValue] = useState(iconInitialValue.name)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const [feedbackStatus, setFeedbackStatus] = useState<{
    type: FeedbackMessageType
    message: string
  }>({
    type: 'text',
    message: '',
  })

  const dispatch = useDispatch()
  const [, saveIconRequest] = useAxios(
    {
      url: `/v4/icons/${icon.hash}`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      saveIconName((e.target as HTMLInputElement).value)
    }
  }

  const saveIconName = async (value: string) => {
    try {
      setNameInputValue(value)
      setFeedbackStatus({ type: 'text', message: 'Saving...' })

      const response = await saveIconRequest({
        data: {
          name: value,
        },
      })

      setFeedbackStatus({ type: 'success', message: 'Icon name updated!' })
      setIcon((prevState) => ({ ...prevState, ...response.data }))
    } catch (error) {
      if (!axios.isCancel(error)) {
        setFeedbackStatus({
          type: 'error',
          message: typeof error === 'string' ? error : 'Failed to save, please try again.',
        })
        console.error(error)
      }
    }
  }

  useEffect(() => {
    setNameInputValue(iconInitialValue.name)
    setIcon(iconInitialValue)
  }, [iconInitialValue])

  useEffect(() => {
    const index = selectedIcons.map((i) => i.hash).findIndex((x) => x === icon.hash)
    if (index >= 0) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [icon.hash, selectedIcons])

  const [selected, setSelected] = useState(false)

  return (
    <>
      <div className={styles.root}>
        <Row wrap={false}>
          <Col flex="22px" className={styles.col}>
            <Checkbox
              checked={selected}
              onChange={() => dispatch(assetsSlice.actions.toggleSelectedIcons(icon))}
            />
          </Col>

          <Col flex="110px" className={styles.col}>
            <IconItemUpload
              iconHash={icon.hash}
              familySlug={family?.slug || ''}
              categorySlug={category?.slug || ''}
              subcategorySlug={subcategory?.slug || ''}
              subcategoryHash={subcategory?.hash || ''}
              svg={icon.svg}
              onComplete={(i) => {
                setIcon({ ...icon, svg: i.svg, name: i.name, tags: i.tags })
                setNameInputValue(i.name)
              }}
            />
          </Col>
          <Col flex="auto" className={styles.col}>
            <Input
              onChange={(e) => {
                setNameInputValue(e.target.value)
              }}
              onBlur={(e) => saveIconName(e.target.value)}
              onKeyUp={(e) => handleKeyUp(e)}
              value={nameInputValue}
            />
            <AntFormItemExplain message={feedbackStatus.message} type={feedbackStatus.type} />

            {showTags && (
              <>
                <TagsSection icon={icon} tags={icon.tags} />
              </>
            )}

            <Collapse ghost>
              <Collapse.Panel header="SVG Source" key="1">
                <p>{icon.svg}</p>
              </Collapse.Panel>
            </Collapse>

            <SizeDisplay icon={icon} />
          </Col>
          <Col flex="72px" className={styles.col}>
            <Tooltip title="edit">
              <Button
                type="text"
                icon={<EditOutlined className={styles.iconAction} />}
                onClick={() => {
                  dispatch(assetsSlice.actions.selectedIcon(icon))
                  dispatch(assetsSlice.actions.toggleDrawer({ visible: true, type: 'icon' }))
                }}
              />
            </Tooltip>

            <Tooltip title="remove">
              <Button
                type="text"
                icon={
                  <DeleteOutlined
                    className={styles.iconAction}
                    onClick={() => setIsDeleteModalVisible(true)}
                  />
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
      {isDeleteModalVisible && (
        <IconsRemoveModal
          icons={[icon]}
          onCancel={() => setIsDeleteModalVisible(false)}
          onSuccess={() => setIsDeleteModalVisible(false)}
        />
      )}
    </>
  )
}

export default IconItem
