import { AppThunk } from '../../app/store'
import api from '../../app/api/api'
import { actions } from './assetsSlice'
import { Icon } from './types'

/**
 * Fetches icons from the backend and sorts them
 */
function getIcons(subcategoryHash: string): AppThunk {
  return async (dispatch) => {
    try {
      dispatch(actions.selectedSubcategory(subcategoryHash))
      dispatch(actions.fetchIconsRequest())

      const { data: icons } = await api.get<Icon[]>(`/v4/subcategories/${subcategoryHash}/icons`)
      dispatch(actions.fetchIconsSuccess(icons))
    } catch (error: any) {
      dispatch(actions.fetchIconsFailed(error.message))
    }
  }
}

export default getIcons
