import React, { useState } from 'react'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import * as assetsSlice from '../assetsSlice'
import IconEmptyState from './icon-empty-state/IconEmptyState'
import { AddTagsMultipleIcons } from './icon-item/tags-section/AddTagsMultipleIcons'
import styles from './IconsPanel.module.scss'
import IconWrapper from './icon-item/IconWrapper'
import getIcons from '../api'
import SortIcons from './SortIcons'
import { Icon } from '../types'
import { SubcategoryStats } from './SubcategoryStats'

function IconsList({ loading, icons }: { loading: boolean; icons: Icon[] }) {
  if (loading) return <LoadingOutlined />

  if (!icons || icons.length === 0) return <IconEmptyState />

  return (
    <>
      {icons.map((i) => (
        <IconWrapper key={i.hash} icon={i} />
      ))}
    </>
  )
}

export default function IconsPanel() {
  const dispatch = useDispatch()
  const icons = useSelector(assetsSlice.selectIcons)
  const loading = useSelector(assetsSlice.selectLoadingIcons)
  const subcategory = useSelector(assetsSlice.selectSubcategory)

  const [filterName, setFilterName] = useState('')

  if (!loading && !subcategory) {
    return null
  }

  /**
   * When value is not empty, display only those assets which contain value in their names.
   */
  const filteredIcons = filterName
    ? icons.filter((icon: Icon) => icon.name.toLowerCase().includes(filterName.toLowerCase()))
    : icons

  const onNameInputChange = (value: string) => setFilterName(value)

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div>
          <label htmlFor="filterName">
            Filter by name:
            <input
              id="filterName"
              type="text"
              name="filterName"
              disabled={loading}
              value={filterName}
              placeholder="name of asset"
              className={styles.input}
              onChange={(event) => onNameInputChange(event.target.value)}
            />
          </label>
          <SortIcons />
          {subcategory && (
            <Tooltip title="Click here to refresh the list and check the updated tags suggestions or just to check the list in case some other person updated it. This saves nothing in the database, so it's safe to use whenever you want.">
              <Button
                icon={<ReloadOutlined />}
                type="link"
                onClick={() => dispatch(getIcons(subcategory.hash))}
              >
                Refresh the list
              </Button>
            </Tooltip>
          )}
        </div>
        <div>
          <AddTagsMultipleIcons />
        </div>
        <div>
          <SubcategoryStats />
        </div>
      </div>

      <div className={styles.content}>
        <IconsList icons={filteredIcons} loading={loading} />
      </div>
    </div>
  )
}
