import axios, { InternalAxiosRequestConfig, AxiosHeaders } from 'axios'
import { makeUseAxios } from 'axios-hooks'
import { getAuthenticationToken } from '../auth/authentication'
import configEnv from '../config'

const axiosInstance = axios.create({ baseURL: configEnv.API_URL || '' })

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = await getAuthenticationToken()
    const updatedConfig = { ...config }

    if (token) {
      updatedConfig.headers = new AxiosHeaders({
        authorization: token,
        tool: 'admin',
      })
    }

    return updatedConfig
  },
  (error) => Promise.reject(error)
)

const useAxios = makeUseAxios({
  axios: axiosInstance,
})

export default useAxios
