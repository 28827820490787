/* eslint-disable react/jsx-props-no-spreading */
import { ArrowRightOutlined, DislikeOutlined, LikeOutlined, MinusOutlined } from '@ant-design/icons'
import { Card, Col, Row, Statistic, Tooltip } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import ColorRepaintIconLink from './color-repaint-icon-link/ColorRepaintIconLink'
import styles from './ColorRepaintResults.module.scss'
import { RepaintResults } from '../../../types'

const ColorRepaintResults: FunctionComponent<{ results: RepaintResults; simulation: boolean }> = ({
  results,
  simulation,
}) => {
  const [activeResultList, setActiveResultList] = useState<'success' | 'notModified' | 'failed'>(
    'success'
  )
  return (
    <div className={styles.root}>
      <h2>Re-paint {simulation ? 'simulation' : ''} results:</h2>
      <Row>
        <Col span={8}>
          <Tooltip title="Click to see more details about successful assets re-painted">
            <Card className={styles.card} onClick={() => setActiveResultList('success')}>
              <Statistic
                title="Successful"
                value={results.totalSuccess}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                prefix={<LikeOutlined />}
              />
            </Card>
          </Tooltip>
        </Col>
        <Col span={8}>
          <Tooltip title="Click to see more details about not modified assets">
            <Card className={styles.card} onClick={() => setActiveResultList('notModified')}>
              <Statistic
                title="Not modified"
                value={results.totalNotModified}
                precision={0}
                valueStyle={{ color: '#4e4d4d' }}
                prefix={<MinusOutlined />}
              />
            </Card>
          </Tooltip>
        </Col>
        <Col span={8}>
          <Tooltip title="Click to see more details about failed assets">
            <Card className={styles.card} onClick={() => setActiveResultList('failed')}>
              <Statistic
                title="Failed"
                value={results.totalFailed}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
                prefix={<DislikeOutlined />}
              />
            </Card>
          </Tooltip>
        </Col>
      </Row>

      {activeResultList === 'success' && (
        <>
          <p>List of re-painted assets:</p>
          {results.totalSuccess > 500 && (
            <p>As too many assets were modified we&apos;re just showing the first 500 below</p>
          )}
          {results.success.map((repaintResult) => (
            <div key={repaintResult.icon.hash}>
              <img
                alt="before svg"
                src={`data:image/svg+xml;utf8,${encodeURIComponent(repaintResult.beforeSvg)}`}
                className={styles.image}
              />
              <ArrowRightOutlined />
              <img
                alt="after svg"
                src={`data:image/svg+xml;utf8,${encodeURIComponent(repaintResult.afterSvg)}`}
                className={styles.image}
              />
              <br />
              <ColorRepaintIconLink icon={repaintResult.icon} />
            </div>
          ))}
        </>
      )}

      {activeResultList === 'notModified' && (
        <>
          <p>List of not modified assets:</p>
          {results.totalNotModified > 500 && (
            <p>As too many assets were modified we&apos;re just showing the first 500 below</p>
          )}

          {results.notModified.map((icon) => (
            <div key={icon.hash}>
              <img
                alt="not modified svg"
                src={`data:image/svg+xml;utf8,${encodeURIComponent(icon.svg)}`}
                className={styles.image}
              />
              <ColorRepaintIconLink icon={icon} />
            </div>
          ))}
        </>
      )}

      {activeResultList === 'failed' && (
        <>
          <p>List of failed assets:</p>
          {results.totalFailed > 500 && (
            <p>As too many assets failed we&apos;re just showing the first 500 below</p>
          )}

          {results.failed.map((icon) => (
            <div key={icon.hash}>
              <img
                alt="failed svg"
                src={`data:image/svg+xml;utf8,${encodeURIComponent(icon.svg)}`}
                className={styles.image}
              />
              <ColorRepaintIconLink icon={icon} />
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default ColorRepaintResults
