import React from 'react'
import { PercentChangeDisplay } from './icon-item/PercentChangeDisplay'
import { useSelector } from 'react-redux'
import { selectSubcategoryStats } from '../assetsSlice'

export function SubcategoryStats() {
  const { compilationSvgSizeChange, runtimeSvgSizeChange } = useSelector(selectSubcategoryStats)

  return (
    <section>
      {compilationSvgSizeChange !== undefined && (
        <p style={{ margin: 0 }}>
          Average size change after compiling SVGs:{' '}
          <PercentChangeDisplay percentChange={compilationSvgSizeChange} />.
        </p>
      )}
      {runtimeSvgSizeChange !== undefined && (
        <p style={{ margin: 0 }}>
          Average size change after user downloaded SVGs:{' '}
          <PercentChangeDisplay percentChange={runtimeSvgSizeChange} />.
        </p>
      )}
    </section>
  )
}
