import { Tooltip } from 'antd'
import React from 'react'
import formatBytes from '../../../../util/bytes.util'
import { Icon } from '../../types'
import { SizeComparisonDisplay } from './SizeComparisonDisplay'

function SizeDisplay({ icon }: { icon: Icon }) {
  const originalSize = formatBytes(icon.originalSvgSize)
  const currentSize = formatBytes(icon.svgSize)
  const lastDownloadSvgSize = formatBytes(icon.lastDownloadSvgSize)

  return (
    <div>
      <small>
        <Tooltip title="Size of original svg file as it was made by creators">
          <strong>Original svg size</strong>
        </Tooltip>
        : {originalSize}
      </small>
      <br />
      <small>
        <Tooltip title="Size of svg file after it is uploaded to our database and compiled with image engine. Compare with original svg size">
          <strong>Current svg size</strong>
        </Tooltip>
        : {currentSize} (
        <SizeComparisonDisplay originalSize={icon.originalSvgSize} currentSize={icon.svgSize} />)
      </small>

      {lastDownloadSvgSize != null && (
        <>
          <br />
          <small>
            <Tooltip title="Size of svg file that a user has downloaded or copied last time. Compare with current svg size">
              <strong>Last download svg size</strong>
            </Tooltip>
            : {lastDownloadSvgSize} (
            <SizeComparisonDisplay
              originalSize={icon.svgSize}
              currentSize={icon.lastDownloadSvgSize}
            />
            )
          </small>
        </>
      )}
    </div>
  )
}

export default SizeDisplay
