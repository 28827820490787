import axios, { InternalAxiosRequestConfig, AxiosHeaders } from 'axios'
import { getAuthenticationToken } from '../auth/authentication'
import configEnv from '../config'

const api = axios.create({ baseURL: configEnv.API_URL })

api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const updatedConfig = { ...config }

  updatedConfig.headers = new AxiosHeaders({
    'X-Requester': 'Streamline_Admin',
    'X-API-Language': 'EN',
    authorization: '',
  })

  const token = await getAuthenticationToken()

  if (token) {
    updatedConfig.headers.set('authorization', token)
  }

  return updatedConfig
})

export default api
