import { Alert, Collapse } from 'antd'
import React, { FunctionComponent } from 'react'
import { ImportIconPackResults } from '../FamilyPackUploadDrawer'

const { Panel } = Collapse
interface FamilyPackUploadProgressProps {
  results: ImportIconPackResults
}

const FamilyPackUploadResults: FunctionComponent<FamilyPackUploadProgressProps> = ({ results }) => (
  <>
    <p>
      The assets are now imported! Please notice that generating the suggested tags is something
      that takes more time and it&apos;s still running in the background.
    </p>
    <Collapse defaultActiveKey={['1']}>
      <Panel header={`new icons imported: ${results.iconsImported.length}`} key="1">
        {results.iconsImported.map((i) => (
          <p key={i}>{i}</p>
        ))}
      </Panel>
      <Panel header={`icons not created: ${results.iconsFailed.length}`} key="4">
        {results.iconsFailed.map((iconFailed) => (
          <>
            <p>{iconFailed.path}</p>
            <Alert message={`${iconFailed.error}`} type="error" />
          </>
        ))}
      </Panel>
    </Collapse>
  </>
)

export default FamilyPackUploadResults
