import React, { FunctionComponent } from 'react'
import envConfig from '../../../../../../app/config'
import styles from './ColorRepaintIconLink.module.scss'
import { IconInAssetsListDto } from '../../../../types'

const ColorRepaintIconLink: FunctionComponent<{ icon: IconInAssetsListDto }> = ({ icon }) => (
  <a
    className={styles.root}
    key={icon.hash}
    href={`${envConfig.REACT_APP_FRONTEND_URL}/${icon.family.productType}/${icon.family.slug}/${icon.category.slug}/${icon.subcategory.slug}/${icon.slug}`}
    target="_blank"
    rel="noreferrer"
  >
    {icon.family.productType}/{icon.family.slug}/{icon.category.slug}/{icon.subcategory.slug}/
    {icon.slug}
  </a>
)

export default ColorRepaintIconLink
