import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, message, Popconfirm } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useAxios from '../../../../../app/api/apiHook'
import handleError from '../../../../../app/api/handleError'
import { usersSlice } from '../../../usersSlice'
import { RoutesEnum } from '../../../../../app/routes/types'

interface DeleteUserButtonProps {
  userHash: string
}

const DeleteUserButton: FunctionComponent<DeleteUserButtonProps> = ({ userHash }) => {
  const [isDeletingUser, setIsDeletingUser] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [, deleteUser] = useAxios(
    {
      url: `/v4/users/${userHash}`,
      method: 'DELETE',
    },
    { manual: true }
  )

  const handleDeleteAccount = async () => {
    try {
      setIsDeletingUser(true)
      await deleteUser({
        data: { forceDelete: true },
      })
      setIsDeletingUser(false)
      navigate(RoutesEnum.USERS)
      dispatch(usersSlice.actions.selectedUser(null))
      message.success('User removed successfully!')
    } catch (error) {
      setIsDeletingUser(false)
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  return (
    <Popconfirm
      title={
        <span>
          Confirm deleting this account ?{' '}
          <strong>
            <span role="img" aria-label="exclamation-icon">
              ‼️
            </span>{' '}
            This action cannot be undone
          </strong>
        </span>
      }
      okType="danger"
      icon={<InfoCircleOutlined />}
      okText="Yes, delete account"
      onConfirm={() => handleDeleteAccount()}
    >
      <Button danger type="link" icon={<DeleteOutlined />} loading={isDeletingUser}>
        Delete User
      </Button>
    </Popconfirm>
  )
}

export default DeleteUserButton
