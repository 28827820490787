import { Spin, Tooltip, Typography, Button, message } from 'antd'
import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import handleError from '../../../app/api/handleError'
import useAxios from '../../../app/api/apiHook'
import { assetsSlice } from '../../assets/assetsSlice'
import { Icon } from '../../assets/types'

const { Text } = Typography

const Restore: FunctionComponent<{
  icon: Icon
  setLoading: (loading: boolean) => void
  loading: boolean
}> = ({ icon, setLoading, loading }) => {
  const dispatch = useDispatch()
  const [, restoreIcon] = useAxios(
    {
      url: `/v4/image-engine/restore-icon/${icon.hash}`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const handleRestore = async () => {
    try {
      setLoading(true)
      const response = await restoreIcon()
      dispatch(assetsSlice.actions.updateIconOnList({ ...icon, svg: response.data }))
      dispatch(
        assetsSlice.actions.toggleDrawer({
          visible: true,
          type: 'recompile-restore',
          data: { ...icon, svg: response.data },
        })
      )
      message.success(`Image's SVG has been restored`)
    } catch (error) {
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    } finally {
      setLoading(false)
    }
  }

  if (icon.svg === icon.originalSvg) {
    return (
      <Text type="secondary">
        Image's current svg matches its original, so there is nothing to restore.
      </Text>
    )
  }

  return (
    <Spin spinning={loading}>
      <Tooltip title="This will replace this image's current SVG with its original one. Note that this action cannot be reverted.">
        <Button htmlType="button" size="large" onClick={handleRestore}>
          Restore SVG
        </Button>
      </Tooltip>
    </Spin>
  )
}

export default Restore
