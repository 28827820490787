import { Tooltip } from 'antd'
import React from 'react'
import { PercentChangeDisplay } from './PercentChangeDisplay'

export function SizeComparisonDisplay({
  originalSize,
  currentSize,
}: {
  originalSize: number
  currentSize: number
}) {
  return (
    <Tooltip title="If size increased after our image engine, then our devs have some work to do!">
      <PercentChangeDisplay
        percentChange={Number(((currentSize / originalSize) * 100 - 100).toFixed(1))}
      />
    </Tooltip>
  )
}
