import { Layout, Menu } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as authSlice from '../../app/auth/authSlice'
import { UserStatus } from '../../app/common-interfaces/User'
import styles from './AppNavbar.module.scss'
import PublishChangesButton from './PublishChangesButton/PublishChangesButton'
import { RoutesEnum } from '../../app/routes/types'

const { Header } = Layout

function AppNavbar() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(authSlice.selectLoggedInUser)

  const hasAccessContentEditor =
    user?.status === UserStatus.ADMIN || user?.status === UserStatus.CONTENT_EDITOR

  const hasAccessCustomerSupport =
    user?.status === UserStatus.ADMIN || user?.status === UserStatus.CUSTOMER_SUPPORT

  const hasAccessToTagging = hasAccessContentEditor || user?.status === UserStatus.TAG_REVIEWER

  return (
    <Header className={styles.root}>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} className={styles.menuLeft}>
        {(hasAccessContentEditor || hasAccessToTagging) && (
          <Menu.Item key="nav:1" onClick={() => navigate(RoutesEnum.ASSETS)}>
            Assets
          </Menu.Item>
        )}

        {hasAccessCustomerSupport && (
          <Menu.Item key="nav:2" onClick={() => navigate(RoutesEnum.USERS)}>
            Users
          </Menu.Item>
        )}

        {hasAccessContentEditor && (
          <Menu.Item key="nav:3" onClick={() => navigate(RoutesEnum.PREFERENCES)}>
            Preferences
          </Menu.Item>
        )}
        {hasAccessContentEditor && (
          <Menu.Item key="nav:4" onClick={() => navigate(RoutesEnum.TAGS_PAGES)}>
            Tags Pages
          </Menu.Item>
        )}
        <Menu.Item key="logout:1" onClick={() => dispatch(authSlice.signOut())}>
          Logout
        </Menu.Item>
      </Menu>
      <Menu theme="dark" mode="horizontal" className={styles.menuRight}>
        <div className={styles.menuRightItems}>
          {hasAccessContentEditor && <PublishChangesButton />}
          Connected as {user?.email}
        </div>
      </Menu>
    </Header>
  )
}

export default AppNavbar
