import { Button, message } from 'antd'
import React, { useState } from 'react'
import useAxios from '../../../app/api/apiHook'
import styles from './PublishChangesButton.module.scss'

function PublishChangesButton() {
  const [loading, setIsLoading] = useState(false)

  const [, clearCacheRequest] = useAxios(
    {
      url: '/v4/families/assets-cache',
      method: 'DELETE',
    },
    { manual: true }
  )

  const handlePublishChangesRequest = async () => {
    try {
      setIsLoading(true)
      await clearCacheRequest()
      setIsLoading(false)
      message.success('Changes published successfully!')
    } catch (error) {
      setIsLoading(false)
      console.error(error)
      message.error('Changes were not published, please try again')
    }
  }

  return (
    <Button
      className={styles.publishChangesButton}
      onClick={() => handlePublishChangesRequest()}
      disabled={loading}
    >
      Publish Changes
    </Button>
  )
}

export default PublishChangesButton
