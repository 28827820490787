import { Collapse, Pagination } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import ColorScanBrokenAsset from './color-scan-broken-asset/ColorScanBrokenAsset'
import styles from './ColorScanBrokenAssets.module.scss'
import { IconInAssetsListDto } from '../../types'

interface ColorScanBrokenAssetsProps {
  invalidIcons: IconInAssetsListDto[]
}

const ASSETS_PER_PAGE = 5 // 5 is a good number to fit wht UI without too much scrooling

const ColorScanInvalidMultipleColorsAssets: FunctionComponent<ColorScanBrokenAssetsProps> = ({
  invalidIcons,
}) => {
  const [brokenIcons, setBrokenIcons] = useState<IconInAssetsListDto[]>(invalidIcons)

  const [page, setPage] = useState(1)

  const onChangePage = (pageNumber: number) => {
    setPage(pageNumber)
  }
  return (
    <>
      {brokenIcons && brokenIcons.length > 0 && (
        <Collapse className={styles.collapseWarning}>
          <Collapse.Panel
            header={`${brokenIcons.length} ${
              brokenIcons.length > 1 ? 'assets' : 'asset'
            } with multiple colors`}
            key="1"
          >
            <p>
              These assets below have more than one color in an one colored family and it should be
              verified by design team and re-uploaded in order for the global colors to work
              properly. You still can run the re-paint tool, but wrong colors still may be shown to
              users on global color.
            </p>
            {[...brokenIcons]
              .slice((page - 1) * ASSETS_PER_PAGE, page * ASSETS_PER_PAGE)
              .map((i) => (
                <div key={i.hash}>
                  <ColorScanBrokenAsset
                    icon={i}
                    onDeleteSuccess={(icon) => {
                      const updateBrokenIcons = brokenIcons.filter((el) => el.hash !== icon.hash)
                      setBrokenIcons(updateBrokenIcons)
                    }}
                  />
                </div>
              ))}
            <Pagination
              simple
              defaultCurrent={1}
              defaultPageSize={ASSETS_PER_PAGE}
              pageSize={ASSETS_PER_PAGE}
              onChange={onChangePage}
              total={brokenIcons.length}
            />
          </Collapse.Panel>
        </Collapse>
      )}
    </>
  )
}

export default ColorScanInvalidMultipleColorsAssets
