import { Button, Drawer, Form, message, Popconfirm, Spin, Tooltip, Typography } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../../assets/assetsSlice'

const { Text } = Typography

const RestoreFamilyDrawer: FunctionComponent = () => {
  const drawerType = 'restore-family'
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const family = useSelector(assetsSlice.selectFamily)

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: drawerType, data: null }))
  }

  const handleSubmit = async () => {
    await form.submit()
  }

  const [, restoreFamily] = useAxios(
    {
      url: `/v4/image-engine/restore-family/${family?.slug}`,
      method: 'PATCH',
    },
    { manual: true, autoCancel: false }
  )

  const handleRestore = async () => {
    try {
      setLoading(true)
      setErrorText(null)

      await restoreFamily()

      message.success(`Image's SVG has been restored`)
    } catch (error) {
      const responseError = handleError(error)
      message.error(`Could not restore an image`)
      setErrorText(responseError)
    } finally {
      setLoading(false)
    }
  }

  if (!family) {
    return null
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => handleCloseDrawer()}
        visible={drawer.type === drawerType && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={loading}
          title="Restore all images in a family"
          onClickCancel={() => handleCloseDrawer()}
          onClickSave={handleSubmit}
          showDeleteButton={false}
        />
        <h3>
          Here you can automatically restore all images SVG from a family to it's original state
        </h3>
        <Spin spinning={loading}>
          <h4>Restore SVG automatically</h4>

          <Tooltip
            placement="bottom"
            title="This will replace this image's current SVG with its original one: the one it was uploaded as. Note that this action cannot be reverted but you can recompile them afterwards."
          >
            <Popconfirm
              title="Are you sure you want to revert all assets' svg to their original state?"
              onConfirm={handleRestore}
              okText="Yes"
              cancelText="No"
            >
              <Button href="#">Restore all SVGs</Button>
            </Popconfirm>
          </Tooltip>
        </Spin>
        {errorText && <Text type="danger">Error: {errorText}</Text>}
      </Drawer>
    </>
  )
}

export default RestoreFamilyDrawer
