import { Button, Collapse, Drawer, Form, message, Spin, Tooltip, Typography } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../../assets/assetsSlice'
import ChangesList from '../changes-list/ChangesList'
import Restore from './Restore'
import SVGComparison from './SVGComparison'
import formatBytes from '../../../util/bytes.util'
import { Icon } from '../../assets/types'

const { Text } = Typography

function RecompileRestoreDrawer() {
  const drawerType = 'recompile-restore'
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)
  // At first the recompilation is in simulation mode, then the admin confirms the change
  const [isSimulation, setIsSimulation] = useState(true)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [updatedSVGPreview, setupdatedSVGPreview] = useState<string | null>(null)
  const family = useSelector(assetsSlice.selectFamily)

  const icon = drawer.data as Icon

  const dispatch = useDispatch()

  const handleCloseDrawer = () =>
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: drawerType, data: null }))

  const handleSubmit = async () => form.submit()

  const [, recompileIcon] = useAxios(
    {
      url: `/v4/image-engine/fix-icon/${icon.hash}?simulation=${isSimulation}`,
      method: 'PATCH',
    },
    { manual: true, autoCancel: false }
  )

  const handleRecompile = async (shouldSimulate: boolean) => {
    try {
      setLoading(true)
      setIsSimulation(shouldSimulate)
      setErrorText(null)

      const {
        data: { updatedSVG },
      } = await recompileIcon({ data: { isSimulation } })

      if (isSimulation) {
        setupdatedSVGPreview(updatedSVG)
      } else {
        setupdatedSVGPreview(null)
        dispatch(assetsSlice.actions.updateIconOnList({ ...icon, svg: updatedSVG }))
        dispatch(
          assetsSlice.actions.toggleDrawer({
            visible: true,
            type: 'recompile-restore',
            data: { ...icon, svg: updatedSVG },
          })
        )
        message.success(`Image's SVG has been recompiled`)
      }
    } catch (error) {
      // @TODO For some reason this functionality throws a cancel error when clicked
      // the second time. Cause is unknown, it should be fixed.
      if (axios.isCancel(error)) {
        message.warning('Please click again')
      } else {
        const responseError = handleError(error)
        message.error(`Could not recompile an image`)
        setErrorText(responseError)
      }
    } finally {
      setLoading(false)
    }
  }

  if (!icon || !family) {
    return null
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => handleCloseDrawer()}
        visible={drawer.type === drawerType && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={loading}
          title="Recompile or restore an image"
          onClickCancel={() => handleCloseDrawer()}
          onClickSave={handleSubmit}
          showDeleteButton={false}
        />
        <h3>Here you can recompile an asset's SVG or restore it to its original state</h3>
        <Collapse defaultActiveKey={['restore']} accordion ghost>
          <Collapse.Panel header="Restore SVG" key="restore">
            <SVGComparison
              firstSVG={icon.svg}
              firstTitle={`Image's current SVG, ${formatBytes(icon.svgSize)}`}
              secondSVG={icon.originalSvg}
              secondTitle={`Image's original SVG, ${formatBytes(icon.originalSvgSize)}`}
            />
            <Restore icon={icon} setLoading={setLoading} loading={loading} />
          </Collapse.Panel>
          <Collapse.Panel header="Recompile SVG" key="fix">
            <Spin spinning={loading}>
              <h4>Recompile SVG</h4>
              <ChangesList strokeAllowed={family.strokeAllowed} />
              <Tooltip title="Preview how the SVG will look like after recompiling. No changes will be saved in the database when you click this.">
                <Button htmlType="button" size="large" onClick={() => handleRecompile(true)}>
                  Preview recompiled SVG
                </Button>
              </Tooltip>
              {updatedSVGPreview && (
                <>
                  <SVGComparison
                    firstSVG={icon.svg}
                    firstTitle={`Image's current SVG, ${formatBytes(icon.svgSize)}`}
                    secondSVG={updatedSVGPreview}
                    secondTitle="Image's SVG after recompiling"
                  />
                  {icon.svg === updatedSVGPreview ? (
                    <Text type="secondary">
                      Image's SVG after recompilation will stay the same, so it is already compiled.
                    </Text>
                  ) : (
                    <Tooltip title="Save these changes in the database. If anything breaks you can restore this image's SVG to its original state above.">
                      <Button htmlType="button" size="large" onClick={() => handleRecompile(false)}>
                        Save compiled SVG
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
            </Spin>
          </Collapse.Panel>
        </Collapse>
        {errorText && <Text type="danger">Error: {errorText}</Text>}
      </Drawer>
    </>
  )
}

export default RecompileRestoreDrawer
