import * as React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { selectIsLoggedIn } from '../auth/authSlice'
import LayoutAdmin from '../../components/LayoutAdmin/LayoutAdmin'
import { RoutesEnum } from './types'

interface PrivateRouteProps {
  component: React.ComponentType
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: RouteComponent }) => {
  const loggedIn = useSelector(selectIsLoggedIn)

  if (!loggedIn) {
    return <Navigate to={RoutesEnum.SIGNIN} />
  }

  return (
    <LayoutAdmin>
      <RouteComponent />
    </LayoutAdmin>
  )
}

export default PrivateRoute
