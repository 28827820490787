import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import AssetsPage from '../../pages/assets/AssetsPage'
import PreferencesPage from '../../pages/preferences/PreferencesPage'
import SigninPage from '../../pages/signin/Signin'
import TagsPagesPage from '../../pages/tags-pages/TagsPagesPage'
import UserDetailsPage from '../../pages/users/details/UserDetailsPage'
import UsersPage from '../../pages/users/UsersPage'
import * as authSlice from '../auth/authSlice'
import { UserStatus } from '../common-interfaces/User'
import PrivateRoute from './private-route'
import { RoutesEnum } from './types'

function AppRouter() {
  const loggedInUser = useSelector(authSlice.selectLoggedInUser)

  return (
    <Routes>
      <Route path={RoutesEnum.SIGNIN} element={<SigninPage />} />
      <Route path="/" element={<Navigate to={RoutesEnum.SIGNIN} replace />} />

      {loggedInUser?.status === UserStatus.CUSTOMER_SUPPORT && (
        <Route path={RoutesEnum.ASSETS} element={<Navigate to={RoutesEnum.USERS} replace />} />
      )}

      <Route path={RoutesEnum.ASSETS} element={<PrivateRoute component={AssetsPage} />} />
      <Route path={RoutesEnum.USERS} element={<PrivateRoute component={UsersPage} />} />
      <Route
        path={RoutesEnum.USERS_DETAILS}
        element={<PrivateRoute component={UserDetailsPage} />}
      />
      <Route path={RoutesEnum.PREFERENCES} element={<PrivateRoute component={PreferencesPage} />} />
      <Route path={RoutesEnum.TAGS_PAGES} element={<PrivateRoute component={TagsPagesPage} />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default AppRouter
