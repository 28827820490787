import { Table } from 'antd'
import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as usersSlice from '../../usersSlice'
import useAxios from '../../../../app/api/apiHook'
import { FamilyGroupPurchases } from '../../../familyGroupPurchases/types'

const UserDetailsAccountFamilyGroupPurchases: FunctionComponent = () => {
  const user = useSelector(usersSlice.selectUser)

  const [{ data: familyGroupPurchases }, getFamilyGroupPurchases] = useAxios<
    FamilyGroupPurchases[]
  >(
    {
      url: `/v4/checkouts/family-group-purchase/${user?.hash}`,
      method: 'GET',
    },
    { manual: true }
  )

  useEffect(() => {
    if (user?.hash) {
      getFamilyGroupPurchases()
    }
  }, [getFamilyGroupPurchases, user?.hash])

  if (!familyGroupPurchases || familyGroupPurchases.length === 0) return null

  const columns = [
    {
      title: 'Family Group',
      dataIndex: ['familyGroup', 'name'],
      key: 'familyGroupName',
    },
    {
      title: 'Purchase Date',
      dataIndex: ['purchases'],
      key: 'purchasedAt',
      render: (purchases: any[]) =>
        purchases.map((purchase) => (
          <div key={purchase.purchasedAt}>
            {new Date(purchase.purchasedAt * 1000).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </div>
        )),
    },
    {
      title: 'Quantity',
      dataIndex: ['purchases'],
      key: 'quantity',
      render: (purchases: any[]) =>
        purchases.map((purchase) => <div key={purchase.purchasedAt}>{purchase.quantity}</div>),
    },
  ]

  return (
    <>
      <h2>Family group purchases</h2>
      <Table
        dataSource={familyGroupPurchases}
        columns={columns}
        rowKey={(record) => record.familyGroup.hash}
        pagination={false}
      />
    </>
  )
}
export default UserDetailsAccountFamilyGroupPurchases
