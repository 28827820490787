import { Checkbox, Modal } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { generateDescriptionsForFamily } from '../assetsSlice'
import { Family } from '../types'

interface GenerateDescriptionsModalModalProps {
  family: Family
  onClose(): void
}

const GenerateDescriptionsModal: FunctionComponent<GenerateDescriptionsModalModalProps> = ({
  family,
  onClose,
}) => {
  const dispatch = useDispatch()
  const [overrideExistingDescriptions, setOverrideExistingDescriptions] = useState(false)

  const handleGenerateDescriptions = async () => {
    dispatch(generateDescriptionsForFamily(family.hash, overrideExistingDescriptions))
    onClose()
  }

  return (
    <Modal
      closable={false}
      visible
      okButtonProps={{ danger: false }}
      onOk={handleGenerateDescriptions}
      onCancel={onClose}
      okText="Generate descriptions"
      title={`Generate descriptions for ${family.name} icons.`}
    >
      <Checkbox
        checked={overrideExistingDescriptions}
        onChange={() => setOverrideExistingDescriptions(!overrideExistingDescriptions)}
      >
        Override all existing descriptions.
      </Checkbox>
    </Modal>
  )
}

export default GenerateDescriptionsModal
