/**
 * Depending on the size, return it in a human readable format in bytes, Kb or Mb
 */
function formatBytes(bytes?: number): string | undefined {
  if (bytes === undefined || bytes === null) return undefined

  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
}

export default formatBytes
