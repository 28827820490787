/* eslint-disable react/jsx-props-no-spreading */
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, List, message, Tooltip } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import * as assetsSlice from '../assetsSlice'
import SubcategoriesHelp from './subcategories-help/SubcategoriesHelp'
import styles from './SubcategoriesPanel.module.scss'
import subcategoriesService from './subcategories.service'
import getIcons from '../api'
import { Subcategory as Subcategory1, Subcategory } from '../types'

/**
 * Swap the order of item from one index to another
 * @param list list of categories
 * @param startIndex index of item to swap
 * @param endIndex index of item to swap with
 * @returns list of categories with the given indices swapped
 */
const reorder = (list: Subcategory1[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const SubcategoriesPanel: FunctionComponent = () => {
  const subcategories = useSelector(assetsSlice.selectSubcategoriesByOrder)
  const subcategorySelected = useSelector(assetsSlice.selectSubcategory)
  const category = useSelector(assetsSlice.selectCategory)
  const loading = useSelector(assetsSlice.selectLoadingSubcategories)
  const [isHovered, setIsHovered] = useState<boolean[]>([])
  const [subcategoriesList, setSubcategoriesList] = useState(subcategories)
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setSubcategoriesList(subcategories)
  }, [subcategories])

  const updateSubcategories = async () => {
    try {
      setIsUpdatingOrder(true)
      const response = await subcategoriesService.updateSubcategoriesOrder(subcategoriesList)
      dispatch(assetsSlice.updateSubcategoriesOrder(response.data))
      message.success('Subcategories updated!')
    } catch (error) {
      message.error('Error updating subcategories!')
    } finally {
      setIsUpdatingOrder(false)
    }
  }

  const openEditDrawer = (subcategoryEdit: Subcategory) => {
    dispatch(
      assetsSlice.actions.toggleDrawer({
        visible: true,
        type: 'subcategory',
        data: subcategoryEdit,
      })
    )
  }

  const handleHoverEnter = (index: number) => {
    setIsHovered((prevState) => ({ ...prevState, [index]: true }))
  }

  const handleHoverLeave = (index: number) => {
    setIsHovered((prevState) => ({ ...prevState, [index]: false }))
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    // means that the draggable was dropped outside the droppable area
    if (!destination) return

    // means that the draggable item was dropped on the same position
    if (source.index === destination.index && source.droppableId === destination.droppableId) return

    setSubcategoriesList((prevState) => reorder(prevState, source.index, destination.index))
  }

  if (!loading && !category) {
    return null
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Sider className={styles.root} width={250}>
        {loading ? (
          <LoadingOutlined className={styles.loader} />
        ) : (
          <Droppable droppableId="tasks">
            {(droppableProvided) => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                className={styles.menu}
              >
                <List
                  header={
                    <div className={styles.subcategoryHeader}>
                      <p className={styles.title}>Subcategories {loading && <LoadingOutlined />}</p>
                      <Button onClick={updateSubcategories} disabled={isUpdatingOrder}>
                        Update
                        {isUpdatingOrder && <LoadingOutlined />}
                      </Button>
                    </div>
                  }
                  bordered
                  dataSource={subcategoriesList}
                  className={styles.root}
                  renderItem={(subcategory, index) => (
                    <Draggable key={subcategory.id} draggableId={subcategory.hash} index={index}>
                      {(draggableProvided) => (
                        <div
                          className={styles.subcategory}
                          onMouseLeave={() => handleHoverLeave(index)}
                          onMouseEnter={() => handleHoverEnter(index)}
                          onClick={() => {
                            dispatch(getIcons(subcategory.hash))
                          }}
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                        >
                          <List.Item>
                            <img
                              src="assets/menu-outline.svg"
                              className={styles.menuIcon}
                              alt=""
                              {...draggableProvided.dragHandleProps}
                            />
                            {subcategory.name}
                            {(subcategorySelected?.hash === subcategory.hash ||
                              isHovered[index]) && (
                              <Tooltip title="Edit subcategory" placement="right">
                                <Button
                                  size="small"
                                  className={styles.editButton}
                                  shape="circle"
                                  icon={<EditOutlined />}
                                  onClick={() => openEditDrawer(subcategory)}
                                />
                              </Tooltip>
                            )}
                          </List.Item>
                        </div>
                      )}
                    </Draggable>
                  )}
                />
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </Sider>
      {subcategories.length > 0 && <SubcategoriesHelp />}
    </DragDropContext>
  )
}

export default SubcategoriesPanel
