import { Button, Card, Col, Form, Input, message, Row, Spin, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Layout from 'antd/lib/layout/layout'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

import useAxios from '../../app/api/apiHook'
import ReindexAllElasticButton from './ReindexAllElasticButton/ReindexAllElasticButton'
import styles from './Preferences.module.scss'
import AddMissingTag from './AddMissingTag/AddMissingTag'
import { ProductTypeEnum } from '../assets/types'

const { Title, Paragraph } = Typography

interface MetaTags {
  metaTitle: string
  metaDescription: string
}

interface MetaTagsPreferences {
  [ProductTypeEnum.ICONS]: MetaTags
  [ProductTypeEnum.ILLUSTRATIONS]: MetaTags
  [ProductTypeEnum.EMOJIS]: MetaTags
  [ProductTypeEnum.ELEMENTS]: MetaTags
  tools: MetaTags
}

interface FormProps {
  metaTags: MetaTagsPreferences
}

const Preferences: FunctionComponent = () => {
  const [form] = Form.useForm()

  const [isSaving, setIsSaving] = useState(false)

  const [{ data, loading, error: loadingPreferencesError }] = useAxios('/v4/preferences')

  const [, savePreferences] = useAxios(
    {
      url: '/v4/preferences',
      method: 'PATCH',
    },
    { manual: true }
  )

  const [{ data: tagsData, loading: loadingCleanupTags }, refetchTags] = useAxios(
    '/v4/preferences/cleanup-tags-status'
  )

  const [, cleanupTags] = useAxios(
    {
      url: '/v4/preferences/cleanup-tags',
      method: 'PATCH',
    },
    { manual: true }
  )

  const handleOnFinish = async (values: FormProps) => {
    try {
      setIsSaving(true)
      await savePreferences({
        data: values,
      })
      setIsSaving(false)
      message.success('Preferences saved successfully!')
    } catch (error: any) {
      setIsSaving(false)
      message.error('Error saving preferences: ', error.messages.join(', '))
    }
  }

  const handleRegenerateTagsPages = () => {
    cleanupTags()
    refetchTags()
  }

  useEffect(() => {
    if (!loading) {
      form.setFieldsValue({
        metaTags: data?.metaTags || '',
      })
    }
  }, [data, form, loading])

  if (loading) return <p>Loading application preferences...</p>
  if (loadingPreferencesError) return <p>Error!</p>

  return (
    <>
      <Layout>
        <div className={styles.root}>
          <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} lg={20}>
              <Title>Application settings</Title>
              <Form
                form={form}
                name="move-icon-form"
                onFinish={handleOnFinish}
                layout="vertical"
                requiredMark="optional"
              >
                <Card title="SEO">
                  <Form.Item>
                    <Paragraph>
                      Here you can change manually the meta title and meta description for the
                      icons/illustration/emojis/elements/freebies/tools pages
                    </Paragraph>
                    <Form.Item>
                      <Form.Item
                        label="Icons Page Title"
                        name={['metaTags', ProductTypeEnum.ICONS, 'metaTitle']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input className={styles.metaTagInput} />
                      </Form.Item>

                      <Form.Item
                        label="Icons Page Description"
                        name={['metaTags', ProductTypeEnum.ICONS, 'metaDescription']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <TextArea className={styles.metaTagInput} />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item>
                      <Form.Item
                        label="Illustrations Page Title"
                        name={['metaTags', ProductTypeEnum.ILLUSTRATIONS, 'metaTitle']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input className={styles.metaTagInput} />
                      </Form.Item>

                      <Form.Item
                        label="Illustrations Page Description"
                        name={['metaTags', ProductTypeEnum.ILLUSTRATIONS, 'metaDescription']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <TextArea className={styles.metaTagInput} />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item>
                      <Form.Item
                        label="Emojis Page Title"
                        name={['metaTags', ProductTypeEnum.EMOJIS, 'metaTitle']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input className={styles.metaTagInput} />
                      </Form.Item>

                      <Form.Item
                        label="Emojis Page Description"
                        name={['metaTags', ProductTypeEnum.EMOJIS, 'metaDescription']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <TextArea className={styles.metaTagInput} />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item>
                      <Form.Item
                        label="Elements Page Title"
                        name={['metaTags', ProductTypeEnum.ELEMENTS, 'metaTitle']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input className={styles.metaTagInput} />
                      </Form.Item>

                      <Form.Item
                        label="Elements Page Description"
                        name={['metaTags', ProductTypeEnum.ELEMENTS, 'metaDescription']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <TextArea className={styles.metaTagInput} />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item>
                      <Form.Item
                        label="Tools Page Title"
                        name={['metaTags', 'tools', 'metaTitle']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input className={styles.metaTagInput} />
                      </Form.Item>

                      <Form.Item
                        label="Tools Page Description"
                        name={['metaTags', 'tools', 'metaDescription']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <TextArea className={styles.metaTagInput} />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item>
                      <Form.Item
                        label="Freebies Page Title"
                        name={['metaTags', 'freebies', 'metaTitle']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input className={styles.metaTagInput} />
                      </Form.Item>

                      <Form.Item
                        label="Freebies Page Description"
                        name={['metaTags', 'freebies', 'metaDescription']}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <TextArea className={styles.metaTagInput} />
                      </Form.Item>
                    </Form.Item>
                  </Form.Item>
                </Card>
                <br />

                <Button
                  loading={isSaving}
                  type="primary"
                  className={styles.saveButton}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            className={styles.rowTools}
          >
            <Col xs={24} lg={20}>
              <Title>Tools</Title>

              <Card title="Cleanup tags">
                <p>
                  This process will remove some wrong tags from our database, based on some rules we
                  implemented like:
                  <br />
                  - Fix some mispells
                  <br />
                  - Replace some plural tags with singular word
                  <br />
                  - Split tags containing "/" in multiple tags
                  <br />
                  - Remove tags with just one or two characters
                  <br />
                  - Remove some special characters
                  <br />
                  - Add the subcategory as a tag in some cases
                  <br />
                  This is a heavy process, so not abuse clicking on this button, just click on it in
                  case you start to see multiple wrong tags saved on our database.
                </p>
                <Button onClick={() => handleRegenerateTagsPages()}>
                  Cleanup tags
                  {tagsData?.isLoading && (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                  )}
                </Button>

                {tagsData?.isLoading && (
                  <>
                    <span>Batch processing status: {tagsData?.batchProcessingStatus}</span>
                    <Button
                      type="text"
                      title="Click to check the updated status"
                      onClick={() => refetchTags()}
                      loading={loadingCleanupTags}
                    >
                      <ReloadOutlined />
                    </Button>
                  </>
                )}
                <p>Last completed run at: {new Date(tagsData?.lastCompletedDate).toUTCString()}</p>
              </Card>

              <br />
              <AddMissingTag />

              <br />
              <Card title="Reindex OpenSearch">
                <Paragraph>
                  Click to re-index all assets on OpenSearch. Use a different index name than the
                  one being used by the application now. After re-indexing is complete, update the{' '}
                  <b>OPENSEARCH_INDEX_NAME</b> environment variable with the new index name in AWS
                  EB.
                </Paragraph>

                <ReindexAllElasticButton />
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  )
}

export default Preferences
