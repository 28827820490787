import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Input, message, Modal } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import useAxios from '../../../../app/api/apiHook'
import styles from './FamilyGroupRemoveModal.module.scss'
import handleError from '../../../../app/api/handleError'
import { FamilyGroup } from '../../types'

interface FamilyRemoveModalProps {
  familyGroup: FamilyGroup
  onCancel(): void
  onConfirm(): void
}

const FamilyGroupRemoveModal: FunctionComponent<FamilyRemoveModalProps> = ({
  familyGroup,
  onCancel,
  onConfirm,
}) => {
  const [deleting, setIsDeleting] = useState(false)
  const [familyGroupNameConfirmation, setFamilyGroupNameConfirmation] = useState('')

  const [, deleteFamilyGroup] = useAxios(
    {
      url: `/v4/family-groups/${familyGroup?.hash}`,
      method: 'DELETE',
    },
    { manual: true }
  )

  const handleDeleteIcon = async () => {
    try {
      if (familyGroupNameConfirmation === familyGroup?.name) {
        setIsDeleting(true)
        await deleteFamilyGroup()
        setIsDeleting(false)
        onConfirm()
        message.success('Family Group removed successfully!')
      } else {
        message.warning('Please write the family name correctly to confirm the exclusion!')
      }
    } catch (error) {
      setIsDeleting(false)
      message.error({ content: handleError(error), duration: 0 })
    }
  }

  return (
    <Modal
      closable={false}
      visible
      okButtonProps={{ danger: true }}
      onOk={handleDeleteIcon}
      onCancel={onCancel}
      okText="Delete"
      confirmLoading={deleting}
      title="Caution!"
    >
      <p>
        <ExclamationCircleOutlined className={styles.deleteModalIcon} />
        Are you sure you want to
        <span className={styles.deleteWord}>
          {` delete the "${familyGroup.name}" family group.`}
        </span>
        ?
      </p>
      <br />
      <p>
        Please write <b>{familyGroup?.name}</b> on the input below to confirm the exclusion:
      </p>
      <Input onChange={(e) => setFamilyGroupNameConfirmation(e.target.value)} />
    </Modal>
  )
}

export default FamilyGroupRemoveModal
