import { Button, Drawer, Form } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../assetsSlice'
import FamilyGroupForm from './family-group-form/FamilyGroupForm'
import useAxios from '../../../app/api/apiHook'
import FamilyGroupRemoveModal from './family-group-remove-modal/FamilyGroupRemoveModal'
import { FamilyGroup } from '../types'

const FamilyGroupDrawer: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)

  const [{ data, loading: isLoadingList, error }, refetchList] = useAxios<FamilyGroup[]>({
    url: `/v4/family-groups`,
    method: 'GET',
  })

  const [isFormVisible, setIsFormVisible] = useState(false)

  const [selectedFamilyGroup, setSelectedFamilyGroup] = useState<FamilyGroup | null>(null)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleFormSubmitSuccess = async () => {
    setLoading(false)
    handleCloseDrawer()
    await refetchList()
  }

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'family', data: null }))
  }

  const handleSubmit = async () => {
    await form.submit()
  }

  const openEditFamilyGroupDrawer = async (familyGroup: FamilyGroup) => {
    setSelectedFamilyGroup(familyGroup)
    setIsFormVisible(true)
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() =>
          dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'family-group' }))
        }
        visible={drawer.type === 'family-group' && drawer.visible}
      >
        {isFormVisible ? (
          <>
            <DrawerHeader
              showSaveButton
              isSaving={loading}
              title={selectedFamilyGroup?.hash ? 'Modify Family Group' : 'New Family Group'}
              onClickCancel={() => setIsFormVisible(false)}
              onClickSave={handleSubmit}
              showDeleteButton={!!selectedFamilyGroup?.hash}
              onClickDelete={() => setIsDeleteModalVisible(true)}
            />
            <FamilyGroupForm
              form={form}
              familyGroup={selectedFamilyGroup}
              onSubmit={() => setLoading(true)}
              onSubmitSuccess={() => handleFormSubmitSuccess()}
              onSubmitError={() => setLoading(false)}
            />
          </>
        ) : (
          <>
            <DrawerHeader
              showSaveButton
              saveButtonTitle="New Family group"
              isSaving={loading}
              title="List of Family Groups"
              onClickSave={() => {
                setSelectedFamilyGroup(null)
                setIsFormVisible(true)
              }}
              onClickCancel={() => handleCloseDrawer()}
              showDeleteButton={false}
            />
            {error && <>Some error happened when loading family groups: {error}</>}
            {isLoadingList && <>Loading family groups...</>}

            {data
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((famGroup) => (
                <div key={famGroup.hash}>
                  {famGroup.name} - {famGroup.families?.length} families
                  <Button type="link" onClick={() => openEditFamilyGroupDrawer(famGroup)}>
                    Click to Edit or Delete
                  </Button>
                </div>
              ))}
          </>
        )}
      </Drawer>
      {isDeleteModalVisible && selectedFamilyGroup && (
        <FamilyGroupRemoveModal
          onCancel={() => setIsDeleteModalVisible(false)}
          familyGroup={selectedFamilyGroup}
          onConfirm={() => {
            setIsDeleteModalVisible(false)
            setIsFormVisible(false)
            refetchList()
          }}
        />
      )}
    </>
  )
}

export default FamilyGroupDrawer
