import { Button, Space, Table } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IUser } from '../../../app/common-interfaces/User'
import * as usersSlice from '../usersSlice'
import styles from './UsersTable.module.scss'
import { RoutesEnum } from '../../../app/routes/types'

function UsersTable() {
  const navigate = useNavigate()
  const list = useSelector(usersSlice.selectUsersList)
  const loading = useSelector(usersSlice.selectLoadingUsers)

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (row: any) => (
        <span>
          {row.firstname} {row.lastname}
        </span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'action',
      render: (u: IUser) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => navigate(RoutesEnum.USERS_DETAILS.replace(':userHash', u.hash))}
          >
            Modify
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <Table
      loading={loading}
      rowKey={(record) => record.hash}
      className={styles.table}
      columns={columns}
      dataSource={list}
    />
  )
}

export default UsersTable
