import { format } from 'date-fns'
import {
  Button,
  Drawer,
  List,
  message,
  Spin,
  Tooltip,
  Typography,
  Collapse,
  Row,
  Checkbox,
} from 'antd'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../../assets/assetsSlice'
import ChangesList from '../changes-list/ChangesList'
import SVGComparison from '../recompile-restore-drawer/SVGComparison'
import styles from './RecompileFamilyDrawer.module.scss'
import RecompilingFamiliesStatusEnum from './types'
import { CompiledIconResponse, CompileResultStatusEnum } from './RecompileFamilyDrawer'

const { Text } = Typography

export type CompiledFamiliesResponse = {
  familySlug: string
  responseErrors: CompiledIconResponse[]
  allIconsCompiled: boolean
  errorPercentage: number
  compileTimeout: boolean
}

function RecompileAllFamiliesDrawer() {
  const drawerType = 'recompile-all-families'
  const drawer = useSelector(assetsSlice.selectDrawer)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [recompiledFamilies, setRecompiledFamilies] = useState<CompiledFamiliesResponse[]>([])
  const [partialRecompiledFamilies, setPartialRecompiledFamilies] = useState<
    CompiledFamiliesResponse[]
  >([])
  const [startedAt, setStartedAt] = useState<Date>(new Date())
  const [finishedAt, setFinishedAt] = useState<Date | null>(null)
  const [lastUpdateAt, setLastUpdateAt] = useState<Date | null>(null)
  const [progress, setProgress] = useState(0)
  const [status, setStatus] = useState(RecompilingFamiliesStatusEnum.NONE)

  const [checkboxRestartedAWS, setCheckboxRestartedAWS] = useState(false)

  const dispatch = useDispatch()

  const handleCloseDrawer = () =>
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: drawerType, data: null }))

  const [, recompileAllFamiliesSVGs] = useAxios(
    {
      url: `/v4/image-engine/fix-families`,
      method: 'PATCH',
    },
    { manual: true, autoCancel: false }
  )

  const [, getRecompileFamiliesResults] = useAxios(
    {
      url: `/v4/image-engine/fix-families-results`,
      method: 'GET',
    },
    { manual: true, autoCancel: false }
  )

  const handleRecompile = async ({
    onlyPartialFamilies,
    startFromLastFamily,
    forceResume,
  }: {
    onlyPartialFamilies?: boolean
    startFromLastFamily: boolean
    forceResume?: boolean
  }) => {
    try {
      setLoading(true)
      setErrorText(null)

      await recompileAllFamiliesSVGs(
        onlyPartialFamilies
          ? {
              data: {
                selectedFamilies: partialRecompiledFamilies.map((family) => family.familySlug),
              },
            }
          : { params: { startFromLastFamily, forceResume } }
      )

      await getResults()

      message.success(
        `Recompiling all families assets has started. This process can take a while, so we'll do everything in the background and once it's done we'll let you know in Slack.`,
        10
      )
    } catch (error) {
      setLoading(false)

      // @TODO For some reason this functionality throws a cancel error when clicked
      // the second time. Cause is unknown, it should be fixed.
      if (axios.isCancel(error)) {
        message.warning('Please click again')
      } else {
        const responseError = handleError(error)
        setErrorText(responseError)
      }
    }
  }

  const getResults = useCallback(async () => {
    try {
      const { data } = await getRecompileFamiliesResults()

      setRecompiledFamilies(
        data.response.filter((response: CompiledFamiliesResponse) => response.allIconsCompiled)
      )

      setPartialRecompiledFamilies(
        data.response.filter((response: CompiledFamiliesResponse) => !response.allIconsCompiled)
      )

      setStartedAt(data.startedAt)
      setFinishedAt(data.finishedAt)
      setLastUpdateAt(data.lastUpdateAt)
      if (data.totalProcessedIcons > 0) {
        setProgress((data.totalProcessedIcons / data.totalIcons) * 100)
      }
      setLoading(data.status === RecompilingFamiliesStatusEnum.IN_PROGRESS)
      setStatus(data.status)
    } catch (error) {
      console.error(error)
      message.error('Could not get results of the compilation')
    }
  }, [getRecompileFamiliesResults])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined
    getResults()

    if (loading) {
      intervalId = setInterval(() => {
        getResults()
      }, 1000 * 10) // 10 seconds
    }
    return () => {
      if (intervalId) return clearInterval(intervalId)
      return undefined
    }
  }, [getResults, loading])

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => handleCloseDrawer()}
        visible={drawer.type === drawerType && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={loading}
          title="Automatically recompile all images' SVG of all families' assets"
          onClickCancel={() => handleCloseDrawer()}
          showDeleteButton={false}
        />

        <Spin
          spinning={loading}
          tip={`Recompiling all assets of all families: ${progress.toFixed(1)}%`}
        >
          <>
            {recompiledFamilies.length > 0 && (
              <>
                <h4>
                  Results from the last recompile process: <br />
                  Started at: {format(new Date(startedAt), 'dd/MM/yy HH:mm')}.
                  {finishedAt ? (
                    <>
                      <br />
                      Finished at: {format(new Date(finishedAt), 'dd/MM/yy HH:mm')}.
                    </>
                  ) : null}
                  <br />
                  {lastUpdateAt ? (
                    <>
                      {' '}
                      Status of process got last update at:{' '}
                      {format(new Date(lastUpdateAt), 'dd/MM/yy HH:mm')}.
                    </>
                  ) : null}
                </h4>

                {status === RecompilingFamiliesStatusEnum.TIMEOUT && (
                  <h3 style={{ color: '#cf1322' }}>
                    The process timed out, some families may not have been updated.
                  </h3>
                )}

                <p style={{ color: '#3f8600' }}>Completely recompiled families</p>

                <List
                  itemLayout="vertical"
                  size="large"
                  pagination={{
                    pageSize: 10,
                    simple: true,
                  }}
                  dataSource={recompiledFamilies || []}
                  renderItem={(family) => (
                    <List.Item key={family.familySlug}>
                      <div>{family.familySlug}</div>
                    </List.Item>
                  )}
                />
              </>
            )}
            {partialRecompiledFamilies.length > 0 && (
              <>
                <p style={{ color: '#cf1322' }}>Partially recompiled families</p>

                <List
                  itemLayout="vertical"
                  size="large"
                  pagination={{
                    pageSize: 10,
                    simple: true,
                  }}
                  dataSource={partialRecompiledFamilies || []}
                  renderItem={(family) => (
                    <List.Item key={family.familySlug}>
                      <Collapse>
                        <Collapse.Panel
                          key={family.familySlug}
                          header={`${family.familySlug} ${
                            family.compileTimeout ? '- the compilation process timed out' : ''
                          }`}
                        >
                          <p>
                            {`Total results: ${
                              family.responseErrors.length
                            } - ${family.errorPercentage?.toFixed(2)}% of error`}
                          </p>
                          <List
                            itemLayout="vertical"
                            size="large"
                            pagination={{
                              pageSize: 10,
                              simple: true,
                            }}
                            dataSource={family.responseErrors || []}
                            renderItem={(icon) => (
                              <List.Item
                                key={icon.updatedSVG}
                                className={
                                  icon.status === CompileResultStatusEnum.ERROR ? styles.Error : ''
                                }
                              >
                                <div>
                                  <SVGComparison
                                    firstSVG={icon.previousSVG}
                                    firstTitle="Image's current SVG"
                                    secondSVG={icon.updatedSVG}
                                    secondTitle="Image's SVG after recompilng"
                                  />
                                  {icon.status === CompileResultStatusEnum.NOT_MODIFIED && (
                                    <Text type="secondary">
                                      Image's SVG after recompilation will stay the same, so it is
                                      already compiled.
                                    </Text>
                                  )}
                                  {icon.message && <>{icon.message}</>}
                                  <br />
                                  <a
                                    href={`https://streamlinehq.com/${icon.path}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {icon.path}
                                  </a>
                                </div>
                              </List.Item>
                            )}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    </List.Item>
                  )}
                />
              </>
            )}

            <h4>Recompile all SVG of all assets of all families automatically</h4>

            {recompiledFamilies.length === 0 && <ChangesList strokeAllowed />}

            <p>
              Be aware that there will be no preview, all assets will be recompiled and saved
              directly in the database.
            </p>

            {status === RecompilingFamiliesStatusEnum.STOPPED && (
              <Row style={{ marginBottom: '10px' }}>
                <p style={{ color: 'red' }}>
                  The last running recompile all families process was paused. So far{' '}
                  {progress.toFixed(1)}% of the families were recompiled. Click the button bellow to
                  resume the process.
                </p>
                <Tooltip title="Recompile remaining families and save the changes to the database by clicking here.">
                  <Button
                    htmlType="button"
                    size="large"
                    onClick={() => handleRecompile({ startFromLastFamily: true })}
                  >
                    Resume paused process
                  </Button>
                </Tooltip>
              </Row>
            )}

            <Tooltip title="Recompile all families and save the changes to the database by clicking here.">
              <Button
                htmlType="button"
                size="large"
                onClick={() => {
                  handleRecompile({ startFromLastFamily: false })
                  return handleCloseDrawer()
                }}
              >
                Recompile all families SVGs
              </Button>
            </Tooltip>

            <Tooltip title="Recompile only families that have been partially compiled.">
              <Button
                htmlType="button"
                size="large"
                onClick={() =>
                  handleRecompile({ startFromLastFamily: false, onlyPartialFamilies: true })
                }
                className={styles.partialFixButton}
                disabled={partialRecompiledFamilies.length === 0}
              >
                Retry only on partially recompiled families
              </Button>
            </Tooltip>
          </>
        </Spin>

        {status === RecompilingFamiliesStatusEnum.IN_PROGRESS ? (
          <Collapse style={{ marginTop: '20px' }}>
            <Collapse.Panel key="1" header="Dev emergency action section">
              <Row style={{ marginTop: '20px' }}>
                <p style={{ color: 'red' }}>
                  If for some reason the process is stuck, but the status is still "in progress",
                  you can force resume here. Notice that this button in intended to be used just as
                  a last resort, so before using it ensure that the process is really stuck, and not
                  just slow! Before clicking on it you should first restart the admin server on AWS,
                  if after restarting it, the process didn't change to STOPPED, then you can click
                  on this button.
                </p>

                <div style={{ width: '100%', marginBottom: '20px' }}>
                  <Checkbox
                    checked={checkboxRestartedAWS}
                    onChange={() => setCheckboxRestartedAWS(!checkboxRestartedAWS)}
                  >
                    I confirm I restarted admin server on AWS, and it's still stuck.
                  </Checkbox>
                </div>

                <Tooltip title="Force resume the process by clicking here.">
                  <Button
                    htmlType="button"
                    size="large"
                    disabled={!checkboxRestartedAWS}
                    onClick={() =>
                      handleRecompile({ startFromLastFamily: true, forceResume: true })
                    }
                  >
                    Force resume
                  </Button>
                </Tooltip>
              </Row>
            </Collapse.Panel>
          </Collapse>
        ) : null}

        {errorText && <Text type="danger">Error: {errorText}</Text>}
      </Drawer>
    </>
  )
}

export default RecompileAllFamiliesDrawer
