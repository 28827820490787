import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../../app/common-interfaces/User'
import { RootState } from '../../app/store'

export interface UsersState {
  selectedUser: IUser | null
  usersList: IUser[]
  loadingUsers: boolean
  errorMessage: string
}

const initialState: UsersState = {
  selectedUser: null,
  usersList: [],
  loadingUsers: true,
  errorMessage: '',
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    selectedUser: (state, action: PayloadAction<IUser | null>) => {
      state.selectedUser = action.payload
    },
    fetchUsersRequest: (state) => {
      state.usersList = []
      state.loadingUsers = true
      state.errorMessage = ''
    },
    fetchUsersSuccess: (state, action: PayloadAction<IUser[]>) => {
      state.usersList = action.payload
      state.loadingUsers = false
      state.errorMessage = ''
    },
    fetchUsersFailed: (state, action: PayloadAction<string>) => {
      state.loadingUsers = false
      state.errorMessage = action.payload
    },
  },
})

export const { actions } = usersSlice

export const selectUser = (state: RootState) => state.users.selectedUser
export const selectUsersList = (state: RootState) => state.users.usersList
export const selectLoadingUsers = (state: RootState) => state.users.loadingUsers

export default usersSlice.reducer
