type Color = string | 'currentColor'

export type IconColors = {
  [color: string]: Color
}

export enum ProductTypeEnum {
  ICONS = 'icons',
  ILLUSTRATIONS = 'illustrations',
  EMOJIS = 'emojis',
  ELEMENTS = 'elements',
}

export enum StyleFilterEnum {
  LINE = 'line',
  SOLID = 'solid',
  FLAT = 'flat',
  DUO = 'duo',
  HANDRAWN = 'handrawn',
  CREATIVE = 'creative',
  GRADIENT = 'gradient',
  REMIX = 'remix',
  NEON = 'neon',
  POP = 'pop',
}

export interface ColorsScan {
  familyColorScheme: IconColors
  brokenIcons: IconInAssetsListDto[]
  invalidMultipleColorsIcons: IconInAssetsListDto[]
  total: number
}

export interface RepaintIconResult {
  icon: IconInAssetsListDto
  beforeSvg: string
  afterSvg: string
}

export interface RepaintResults {
  totalSuccess: number
  totalNotModified: number
  totalFailed: number
  success: RepaintIconResult[]
  notModified: IconInAssetsListDto[]
  failed: IconInAssetsListDto[]
}

export interface FamilyGroup {
  hash: string
  name: string
  slug: string
  description: string
  subtitle: string
  siteUrl: string
  color: string
  order: number
  families?: Family[]
  isAvailableForSale: boolean
  // Price for sale for one-time purchases of this family group
  priceForSale: number | null
  zipUpdatedAt: Date
}

export interface Family {
  id: number
  hash: string
  name: string
  slug: string
  order: number
  defaultSize: number
  isFree: boolean
  defaultLazyLoadItems: number
  cover: string
  extra: { is_beta: boolean }
  iconCount: number
  createdAt: Date
  updatedAt: Date
  visible: boolean
  description: string
  isNew: boolean
  legacyHavePremium: boolean
  onlyLegacyVisible: boolean
  oneColored: boolean
  defaultSidebarSize: number
  defaultDownloadSize: number
  productType: ProductTypeEnum
  maxFreeSizeDownload: number
  hasGlobalColors: boolean
  metaTitle: string
  metaDescription: string
  strokeAllowed: boolean
  defaultStrokeWidth: boolean
  visibleOnlyOnFreebiesTab: boolean
  paymentLink: string
  sendOwlFileId: string
  familyGroupHash?: string
  style?: StyleFilterEnum
  displayInFreeAndPremium?: boolean
  authorName?: string
  authorLink?: string
  licenseName?: string
  licenseLink?: string
  isThirdParty: boolean
  relatedFamilyHash?: string
}

export interface Category {
  id: number
  hash: string
  name: string
  path: string
  family: Family
  slug: string
  isFree: boolean
  order: number
  isNew: boolean
  visible: boolean
}

export interface Subcategory {
  id: number
  hash: string
  name: string
  slug: string
  path: string
  category: Category
  visible: boolean
  order: number
}

export type Icon = {
  id: number
  hash: string
  name: string
  description: string | null
  path: string
  slug: string
  imagePublicId: string
  svg: string
  originalSvg: string
  tags: string[]
  /**
   * @deprecated Now tags will be stored in only one place, the tags field. That's because now we need to store the tags orderred.
   * by relevance.
   */
  autoTags: string[]
  /**
   * @deprecated Now tags will be stored in only one place, the tags field. That's because now we need to store the tags orderred.
   * by relevance.
   */
  autoTagsSynonyms: string[]
  visible: boolean
  createdAt: Date
  updatedAt: Date
  svgSize: number
  originalSvgSize: number
  lastDownloadSvgSize: number
}

export type IconInAssetsListDto = {
  name: string

  description: string | null

  hash: string

  slug: string

  svg: string

  imagePublicId?: string | null

  hasPremiumAccess?: boolean

  visible: boolean

  family: Family

  category: Category

  subcategory: Subcategory
}

export type DrawerType =
  | 'family'
  | 'category'
  | 'subcategory'
  | 'icon'
  | 'family-upload'
  | 'multiple-icons'
  | 'color-scan'
  | 'recompile-restore'
  | 'recompile-family'
  | 'recompile-all-families'
  | 'restore-family'
  | 'family-group'
  | 'upload-category-assets'
  | 'fix-tags'

export interface AssetsDrawer {
  visible: boolean
  type: DrawerType
  data?: Family | Subcategory | Category | Icon | FamilyGroup | null
}

export enum SortIconsByEnum {
  NAME = 'name',
  SVG_SIZE = 'svgSize',
  ORIGINAL_SVG_SIZE = 'originalSvgSize',
  LAST_DOWNLOAD_SVG_SIZE = 'lastDownloadSvgSize',
  /**
   * How much did the SVG size change during compilation, in %?
   */
  COMPILATION_SVG_SIZE_CHANGE = 'compilationSvgSizeChange',
  /**
   * How much did the SVG size change during runtime on last download/copy, in %?
   */
  RUNTIME_SVG_SIZE_CHANGE = 'runtimeSvgSizeChange',
}

export interface SubcategoryStats {
  /**
   * How much did the SVG size change during compilation on average for this subcategory, in %?
   */
  compilationSvgSizeChange?: number
  /**
   * How much did the SVG size change during runtime (last download/copy) on average for this subcategory, in %?
   */
  runtimeSvgSizeChange?: number
}

export interface AssetsState {
  families: Family[]
  family: Family | null
  category: Category | null
  categories: Category[]
  subcategory: Subcategory | null
  subcategories: any[]
  icons: Icon[]
  subcategoryStats: SubcategoryStats
  categoryIcons: Icon[]
  familyIcons: Icon[]
  totalCategoryIcons: number
  totalFamilyIcons: number
  icon: Icon | null
  selectedIcons: Icon[]
  showTags: boolean
  previewSize: number
  selectAllCheckbox: boolean
  loadingFamilies: boolean
  loadingCategories: boolean
  loadingSubcategories: boolean
  loadingIcons: boolean
  loadingCategoriesOrder: boolean
  errorMessage: string
  drawer: AssetsDrawer
  updatingModelDataset: boolean
  autoTaggingAssets: boolean
  isUpdatingAssetsOnSendOwl: boolean
  sortIconsBy: SortIconsByEnum
}
