import { Drawer } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import MultipleIconsUpload from '../../../components/MultipleIconsUpload/MultipleIconsUpload'
import * as assetsSlice from '../assetsSlice'
import styles from './MultipleIconsDrawer.module.scss'

const MultipleIconsDrawer: FunctionComponent = () => {
  const dispatch = useDispatch()

  const drawer = useSelector(assetsSlice.selectDrawer)
  const [totalIconsSuccess, setTotalIconsSuccess] = useState(0)
  const [totalIconsFailed, setTotalIconsFailed] = useState(0)

  const subcategory = useSelector(assetsSlice.selectSubcategory)

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'multiple-icons' }))
  }

  return (
    <Drawer
      destroyOnClose
      width={640}
      placement="right"
      closable={false}
      maskClosable={false}
      onClose={() =>
        dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'multiple-icons' }))
      }
      visible={drawer.type === 'multiple-icons' && drawer.visible}
      className={styles.root}
    >
      <div>
        <DrawerHeader
          showSaveButton={false}
          isSaving={false}
          title="Upload icons"
          onClickCancel={() => handleCloseDrawer()}
        />
        <p>
          Drag and Drop multiple files here to save new assets on the {subcategory?.name}{' '}
          subcategory. All the uploaded svg&apos;s here will generate a new asset, so please be
          carefull to not upload duplicated icons.
        </p>
        {totalIconsSuccess > 0 && (
          <p className={styles.successMessage}>{totalIconsSuccess} assets uploaded successfully!</p>
        )}
        {totalIconsFailed > 0 && (
          <p className={styles.failedMessage}>{totalIconsFailed} assets upload failed!</p>
        )}

        <MultipleIconsUpload
          onUploadComplete={() => setTotalIconsSuccess((previousState) => previousState + 1)}
          onUploadFailed={() => setTotalIconsFailed((previousState) => previousState + 1)}
        />
      </div>
    </Drawer>
  )
}

export default MultipleIconsDrawer
