import { Form, message } from 'antd'
import React, { FunctionComponent, useEffect } from 'react'
import useAxios from '../../../../app/api/apiHook'
import handleError from '../../../../app/api/handleError'
import DrawerTwoColumns from '../../../../components/DrawerTwoColumns/DrawerTwoColumns'
import FamilyGroupFormLeftColumn from './family-group-form-left-column/FamilyGroupFormLeftColumn'
import { FamilyGroup } from '../../types'

interface FamilyFormProps {
  onSubmit(): void
  onSubmitSuccess(): void
  onSubmitError(): void
  familyGroup: FamilyGroup | null
  form: any
}

const FamilyGroupForm: FunctionComponent<FamilyFormProps> = ({
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
  familyGroup,
  form,
}) => {
  const [, editFamilyGroup] = useAxios(
    {
      url: `/v4/family-groups/${familyGroup?.hash}`,
      method: 'PUT',
    },
    { manual: true }
  )

  const [, createFamilyGroup] = useAxios(
    {
      url: '/v4/family-groups',
      method: 'POST',
    },
    { manual: true }
  )

  const onFinish = async (formValues: any) => {
    onSubmit()
    const familyGroupData = {
      ...formValues,
      slug: formValues.slug.toLowerCase().replace(/ /g, '-'),
    }

    try {
      if (familyGroup?.hash) {
        await editFamilyGroup({
          data: familyGroupData,
        })
      } else {
        await createFamilyGroup({
          data: familyGroupData,
        })
      }
      message.success('Family Group saved successfully!')

      onSubmitSuccess()
    } catch (error) {
      onSubmitError()
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  useEffect(() => {
    // This was the only workaround that I found, as calling form.resetFields() from the parent component didn't work
    // So here we reset the fields whenver the familyGroup hash changes
    // Making it possible to edit an icon and then create a new one without the old data being kept
    form.resetFields()
  }, [familyGroup?.hash, form])

  return (
    <Form
      form={form}
      name="family-form"
      onFinish={onFinish}
      layout="vertical"
      requiredMark="optional"
      initialValues={{
        name: familyGroup?.name || '',
        slug: familyGroup?.slug || '',
        description: familyGroup?.description || '',
        subtitle: familyGroup?.subtitle || '',
        siteUrl: familyGroup?.siteUrl || '',
        order: familyGroup?.order || 1,
        color: familyGroup?.color || '#000000',
        isAvailableForSale: familyGroup?.isAvailableForSale || false,
        priceForSale: familyGroup?.priceForSale || '',
      }}
    >
      <DrawerTwoColumns
        leftCol={
          <FamilyGroupFormLeftColumn
            hasPremiumFamilies={familyGroup?.families?.some((family) => !family.isFree) ?? false}
          />
        }
        rightCol={<> </>}
      />
    </Form>
  )
}

export default FamilyGroupForm
