import React, { useEffect, useRef, useState } from 'react'
import IconItem from './IconItem'

import { Icon } from '../../types'

/**
 * Wrapper for the IconItem component that uses an IntersectionObserver
 * to only render the icon when it is visible in the viewport.
 *
 * Use this wrapper to improve performance when rendering a large number of icons.
 */
export default function IconWrapper({ icon }: { icon: Icon }) {
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.disconnect()
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref])

  return (
    <div ref={ref} style={{ minHeight: '100px' }}>
      {isVisible && <IconItem icon={icon} />}
    </div>
  )
}
