import { Typography, Collapse } from 'antd'
import React from 'react'
import styles from './RecompileRestore.module.scss'

const { Text } = Typography

function SVGComparison({
  firstSVG,
  secondSVG,
  firstTitle,
  secondTitle,
}: {
  firstSVG: string
  secondSVG: string
  firstTitle: string
  secondTitle: string
}) {
  return (
    <>
      <h3>Compare SVGs</h3>
      <div className={styles.comparison}>
        <div>
          <h4>{firstTitle}</h4>
          <figure className={styles.figure}>
            <img
              alt={firstTitle}
              src={`data:image/svg+xml;utf8,${encodeURIComponent(firstSVG)}`}
              className={styles.image}
            />
          </figure>
          <Collapse ghost>
            <Collapse.Panel header={firstTitle} key="current">
              <Text code>{firstSVG}</Text>
            </Collapse.Panel>
          </Collapse>
        </div>
        <div>
          {secondSVG ? (
            <>
              <h4>{secondTitle}</h4>
              <figure className={styles.figure}>
                <img
                  alt={secondTitle}
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(secondSVG)}`}
                  className={styles.image}
                />
              </figure>
              <Collapse ghost>
                <Collapse.Panel header={secondTitle} key="original">
                  <Text code>{secondSVG}</Text>
                </Collapse.Panel>
              </Collapse>
            </>
          ) : (
            <p>There is nothing to compare with yet</p>
          )}
        </div>
      </div>
    </>
  )
}

export default SVGComparison
