import { DislikeOutlined, LikeOutlined, MinusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  List,
  message,
  Row,
  Spin,
  Statistic,
  Tooltip,
  Typography,
  Result,
} from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../../assets/assetsSlice'
import ChangesList from '../changes-list/ChangesList'
import SVGComparison from '../recompile-restore-drawer/SVGComparison'
import styles from './RecompileFamilyDrawer.module.scss'
import RecompilingFamiliesStatusEnum from './types'

const { Text } = Typography

export enum CompileResultStatusEnum {
  ERROR = 'ERROR',
  NOT_MODIFIED = 'NOT_MODIFIED',
  SUCCESS = 'SUCCESS',
}

export type CompiledIconResponse = {
  previousSVG: string
  updatedSVG: string
  status: CompileResultStatusEnum
  path: string
  message?: string
}

export type CompileFamilyResult = {
  familySlug: string
  response: CompiledIconResponse[]
  responseErrors: CompiledIconResponse[]
  allIconsCompiled: boolean
  errorPercentage: number
  compileTimeout: boolean
  simulation: boolean
  status: RecompilingFamiliesStatusEnum
}

const RecompileFamilyDrawer: FunctionComponent = () => {
  const drawerType = 'recompile-family'
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)
  // At first the recompilation is in simulation mode, then the admin confirms the change
  const [isSimulation, setIsSimulation] = useState(true)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [updatedSVGPreviews, setUpdatedSVGPreviews] = useState<
    | {
        previousSVG: string
        updatedSVG: string
        status: CompileResultStatusEnum
        path: string
        message?: string
      }[]
    | null
  >(null)
  const family = useSelector(assetsSlice.selectFamily)

  const dispatch = useDispatch()

  const handleCloseDrawer = () =>
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: drawerType, data: null }))

  const handleSubmit = async () => form.submit()

  const [, recompileIcon] = useAxios<{
    message: string
  }>(
    {
      url: `/v4/image-engine/recompile-family/${family?.slug}?simulation=${isSimulation}`,
      method: 'PATCH',
    },
    { manual: true, autoCancel: false }
  )

  const [, getRecompilationFamilyResult] = useAxios<CompileFamilyResult>(
    {
      url: '/v4/image-engine/recompile-family-results',
      method: 'GET',
    },
    { manual: true, autoCancel: false }
  )

  async function handleRecompile(shouldSimulate: boolean) {
    try {
      setLoading(true)
      setIsSimulation(shouldSimulate)
      setErrorText(null)

      const { data } = await recompileIcon({ data: { isSimulation } })

      message.success(data.message)
    } catch (error) {
      // @TODO For some reason this functionality throws a cancel error when clicked
      // the second time. Cause is unknown, it should be fixed.
      if (axios.isCancel(error)) {
        message.warning('Please click again')
      } else {
        const responseError = handleError(error)
        message.error(`Could not compile`)
        setErrorText(responseError)
      }
      setLoading(false)
    }
  }

  const updateRecompilationFamilyResult = useCallback(async () => {
    const { data: recompilationFamilyResult } = await getRecompilationFamilyResult()

    if (recompilationFamilyResult.familySlug !== family?.slug) {
      return
    }

    if (recompilationFamilyResult.status === RecompilingFamiliesStatusEnum.IN_PROGRESS) {
      setLoading(true)
      return
    }

    if (recompilationFamilyResult.status === RecompilingFamiliesStatusEnum.FINISHED) {
      setLoading(false)
    }

    // First error, then success, then not modified
    const sortedUpdatedSVGs = recompilationFamilyResult.response.sort(
      (a: { status: CompileResultStatusEnum }, b: { status: CompileResultStatusEnum }) => {
        const statusValue = (status: CompileResultStatusEnum) => {
          switch (status) {
            case CompileResultStatusEnum.ERROR:
              return 1
            case CompileResultStatusEnum.SUCCESS:
              return 2
            default:
              return 3
          }
        }

        return statusValue(a.status) - statusValue(b.status)
      }
    )

    if (recompilationFamilyResult.simulation) {
      setUpdatedSVGPreviews(sortedUpdatedSVGs)
    } else {
      setUpdatedSVGPreviews(null)
      message.success('Images in this family have been recompiled')
    }
  }, [family?.slug, getRecompilationFamilyResult])

  // If modal is open, we request the logs every few seconds
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined

    if (loading) {
      const fiveSeconds = 1000 * 5
      intervalId = setInterval(() => updateRecompilationFamilyResult(), fiveSeconds)
    }

    return () => {
      if (intervalId) return clearInterval(intervalId)
      return undefined
    }
  }, [loading, updateRecompilationFamilyResult])

  useEffect(() => {
    updateRecompilationFamilyResult()
  }, [updateRecompilationFamilyResult])

  if (!family) {
    return null
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable
        onClose={() => handleCloseDrawer()}
        visible={drawer.type === drawerType && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={loading}
          title="Recompile all images' SVG of this family"
          onClickCancel={() => handleCloseDrawer()}
          onClickSave={handleSubmit}
          showDeleteButton={false}
        />
        <p>
          Failed recompilation results will be shown first, then the successful ones, then the not
          modified ones.
        </p>
        <Spin spinning={loading}>
          {updatedSVGPreviews && updatedSVGPreviews.length > 0 ? (
            <>
              {updatedSVGPreviews.length ===
              updatedSVGPreviews.filter(
                (svg) => svg.status === CompileResultStatusEnum.NOT_MODIFIED
              ).length ? (
                <>
                  <Result
                    status="success"
                    title="All images are already recompiled!"
                    subTitle="All images are already recompiled for this family, there is nothing to do here."
                  />

                  <>
                    <h4>Recompile all images</h4>
                    <Tooltip title="Preview how the SVG will look like after recompilng. No changes will be saved in the database when you click this.">
                      <Button htmlType="button" size="large" onClick={() => handleRecompile(true)}>
                        Preview SVG recompilation
                      </Button>
                    </Tooltip>
                  </>
                </>
              ) : (
                <>
                  <Row>
                    <Col span={8}>
                      <Card className={styles.card}>
                        <Statistic
                          title="Successful"
                          value={
                            updatedSVGPreviews.filter(
                              (svg) => svg.status === CompileResultStatusEnum.SUCCESS
                            ).length
                          }
                          precision={0}
                          valueStyle={{ color: '#3f8600' }}
                          prefix={<LikeOutlined />}
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card className={styles.card}>
                        <Statistic
                          title="Not modified"
                          value={
                            updatedSVGPreviews.filter(
                              (svg) => svg.status === CompileResultStatusEnum.NOT_MODIFIED
                            ).length
                          }
                          precision={0}
                          valueStyle={{ color: '#4e4d4d' }}
                          prefix={<MinusOutlined />}
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card className={styles.card}>
                        <Statistic
                          title="Failed"
                          value={
                            updatedSVGPreviews.filter(
                              (svg) => svg.status === CompileResultStatusEnum.ERROR
                            ).length
                          }
                          precision={0}
                          valueStyle={{ color: '#cf1322' }}
                          prefix={<DislikeOutlined />}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <p>{`Total results: ${updatedSVGPreviews.length}`}</p>
                  <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                      pageSize: 10,
                      simple: true,
                    }}
                    dataSource={updatedSVGPreviews || []}
                    renderItem={(icon) => (
                      <List.Item
                        key={icon.updatedSVG}
                        className={
                          icon.status === CompileResultStatusEnum.ERROR ? styles.Error : ''
                        }
                      >
                        <div>
                          <SVGComparison
                            firstSVG={icon.previousSVG}
                            firstTitle="Image's current SVG"
                            secondSVG={icon.updatedSVG}
                            secondTitle="Image's SVG after recompilng"
                          />
                          {icon.status === CompileResultStatusEnum.NOT_MODIFIED && (
                            <Text type="secondary">
                              Image's SVG after recompilng will stay the same, so it is already
                              compiled.
                            </Text>
                          )}
                          {icon.message && <>{icon.message}</>}
                          <br />
                          <a
                            href={`https://streamlinehq.com/${icon.path}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {icon.path}
                          </a>
                        </div>
                      </List.Item>
                    )}
                  />
                  <Tooltip title="Save these changes in the database. If anything breaks you can restore this image's SVG to its original state above.">
                    <Button htmlType="button" size="large" onClick={() => handleRecompile(false)}>
                      Save recompiled SVG
                    </Button>
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              <h4>Recompile SVG</h4>
              <ChangesList strokeAllowed={family.strokeAllowed} />
              <Tooltip title="Preview how the SVG will look like after recompilng. No changes will be saved in the database when you click this.">
                <Button htmlType="button" size="large" onClick={() => handleRecompile(true)}>
                  Preview SVG recompilation
                </Button>
              </Tooltip>
            </>
          )}
        </Spin>
        {errorText && <Text type="danger">Error: {errorText}</Text>}
      </Drawer>
    </>
  )
}

export default RecompileFamilyDrawer
