import React from 'react'

function formatPercent(value: number) {
  return value % 1 === 0 ? value.toFixed(0) : value.toFixed(1)
}

export function PercentChangeDisplay({ percentChange }: { percentChange: number }) {
  let changeText: string
  let color: string

  if (percentChange > 0) {
    changeText = `increased by ${formatPercent(percentChange)}%`
    color = '#f5222d' // red if increased
  } else if (percentChange < 0) {
    changeText = `decreased by ${formatPercent(Math.abs(percentChange))}%`
    color = '#52c41a' // green if optimized
  } else {
    changeText = `didn't change`
    color = '#52c41a' // green if no change
  }

  // yellow if decreased by less than 20% and not zero
  if (percentChange <= 20 && percentChange > 0) {
    color = '#faad14'
  }

  return <span style={{ color }}>{changeText}</span>
}
