import auth from './firebaseAuthentication'

const TOKEN_KEY = '@Streamline::ApiToken'

export async function getAuthenticationToken(): Promise<string | null> {
  if (auth.currentUser) {
    return auth.currentUser.getIdToken()
  }

  return null
}

export function clearAuthenticationTokenFromLocalStorage() {
  return window.localStorage.removeItem(TOKEN_KEY)
}
