import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import api from '../../app/api/api'
import { RootState, AppThunk } from '../../app/store'
import { IndexingResult, IndexingStatusEnum } from './ReindexAllElasticButton/types'

export interface PreferencesState {
  indexingResult: IndexingResult
  isIndexing: boolean
  errorMessage: string
}

const initialState: PreferencesState = {
  indexingResult: { indexName: '', status: IndexingStatusEnum.NONE, unindexedFamilies: [] },
  isIndexing: false,
  errorMessage: '',
}

export const preferencesSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setIsIndexing: (state, action: PayloadAction<boolean>) => {
      state.isIndexing = action.payload
    },
    fetchIndexingResultSuccess: (state, action: PayloadAction<IndexingResult>) => {
      state.indexingResult = action.payload
      state.isIndexing = action.payload.status === IndexingStatusEnum.IN_PROGRESS
    },
    fetchIndexingResultFailed: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
  },
})

export const { actions } = preferencesSlice

export const reIndexAssets =
  (indexName: string, familiesSlugs?: string[]): AppThunk =>
  async (dispatch) => {
    try {
      await api.post('/v4/open-search/index', {
        indexName,
        familiesSlugs,
      })
      message.info('Assets are being reindexed. It may take several minutes.', 5)
      dispatch(getIndexingResult())
    } catch (error: any) {
      message.error('Reindex was not started, please try again')
    }
  }

export const getIndexingResult = (): AppThunk => async (dispatch) => {
  try {
    const response = await api.get('/v4/open-search/index-result')
    dispatch(actions.fetchIndexingResultSuccess(response.data))
  } catch (error: any) {
    dispatch(actions.fetchIndexingResultFailed(error.message))
  }
}

export const addMissingTag =
  (tag: string): AppThunk =>
  async () => {
    try {
      const response = await api.patch('/v4/ai/auto-tagging/synonyms', {
        tag,
      })
      message.success(response.data.message)
    } catch (error: any) {
      message.error('Error adding tag: ', error.messages.join(', '))
    }
  }

export const selectIndexingResult = (state: RootState) => state.preferences.indexingResult
export const selectIsIndexing = (state: RootState) => state.preferences.isIndexing

export const { setIsIndexing } = preferencesSlice.actions

export default preferencesSlice.reducer
