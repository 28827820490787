import { Icon, SortIconsByEnum } from './types'

export function sortIcons(icons: Icon[], sortBy: SortIconsByEnum): Icon[] {
  const sortedIcons = [...icons]

  if (sortBy === SortIconsByEnum.SVG_SIZE) {
    sortedIcons.sort((a, b) => b.svgSize - a.svgSize)
  } else if (sortBy === SortIconsByEnum.ORIGINAL_SVG_SIZE) {
    sortedIcons.sort((a, b) => b.originalSvgSize - a.originalSvgSize)
  } else if (sortBy === SortIconsByEnum.COMPILATION_SVG_SIZE_CHANGE) {
    sortedIcons.sort((a, b) => {
      const percentA = ((a.originalSvgSize - a.svgSize) / a.svgSize) * 100
      const percentB = ((b.originalSvgSize - b.svgSize) / b.svgSize) * 100
      return percentA - percentB
    })
  } else if (sortBy === SortIconsByEnum.LAST_DOWNLOAD_SVG_SIZE) {
    sortedIcons.sort((a, b) => {
      if (a.lastDownloadSvgSize === undefined) return 1
      if (b.lastDownloadSvgSize === undefined) return -1
      return b.lastDownloadSvgSize - a.lastDownloadSvgSize
    })
  } else if (sortBy === SortIconsByEnum.RUNTIME_SVG_SIZE_CHANGE) {
    sortedIcons.sort((a, b) => {
      const percentA = ((a.lastDownloadSvgSize - a.svgSize) / a.svgSize) * 100
      const percentB = ((b.lastDownloadSvgSize - b.svgSize) / b.svgSize) * 100
      return percentA - percentB
    })
  } else {
    // Default, how icons are returned from backend
    sortedIcons.sort((a, b) => a.name.localeCompare(b.name))
  }

  return sortedIcons
}

/**
 * Tells how much did changedField change compared to originalField, in %.
 * When any field is undefined, it's skipped.
 */
export function calculateAverageNumberChange(
  icons: Icon[],
  originalField: keyof Icon,
  changedField: keyof Icon
): number | undefined {
  // Filter out icons with undefined, null, or non-number sizes
  const validIcons = icons.filter((icon) => {
    const size1 = icon[changedField]
    const size2 = icon[originalField]
    return (
      size1 !== undefined &&
      size1 !== null &&
      typeof size1 === 'number' &&
      size2 !== undefined &&
      size2 !== null &&
      typeof size2 === 'number'
    )
  })

  if (validIcons.length === 0) return undefined

  const totalPercentageChange = validIcons.reduce((total, icon) => {
    const size1 = icon[changedField]
    const size2 = icon[originalField]

    // Ensure the fields are numbers
    if (typeof size1 !== 'number' || typeof size2 !== 'number') {
      return total // Skip invalid entries
    }

    const percentageChange = ((size1 - size2) / size2) * 100
    return total + percentageChange
  }, 0)

  // Calculate the average
  const averagePercentageChange = totalPercentageChange / validIcons.length

  // Return undefined if the result is not a valid number
  return isNaN(averagePercentageChange) ? undefined : averagePercentageChange
}
