import { Button, Input, InputRef } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../../app/api/apiHook'
import { FeedbackMessageType } from '../../../../../app/types'
import AntFormItemExplain from '../../../../../components/AntFormItemExplain/AntFormItemExplain'
import * as assetsSlice from '../../../assetsSlice'
import { selectSelectedIcons } from '../../../assetsSlice'
import styles from './TagsSection.module.scss'
import getIcons from '../../../api'

export function AddTagsMultipleIcons() {
  const [inputEnabled, setInputEnabled] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const selectedIcons = useSelector(selectSelectedIcons)
  const subcategory = useSelector(assetsSlice.selectSubcategory)
  const dispatch = useDispatch()

  const [feedbackStatus, setFeedbackStatus] = useState<{
    type: FeedbackMessageType
    message: string
  }>({
    type: 'text',
    message: '',
  })

  const saveInputRef = useRef<InputRef>(null)

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const addTag = async () => {
    try {
      await patchTag({
        data: { newTag: inputValue, iconsHashes: selectedIcons.map((icon) => icon.hash) },
      })
      setInputValue('')
      refreshIcons()
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        setFeedbackStatus({ type: 'error', message: error.response.data.messages.join(', ') })
      }
    }
  }

  const [, patchTag] = useAxios(
    {
      url: `/v4/icons/tags`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const refreshIcons = () => {
    if (subcategory) {
      dispatch(getIcons(subcategory.hash))
    }
  }

  useEffect(() => {
    setInputEnabled(selectedIcons.length > 0)
  }, [selectedIcons])

  return (
    <div>
      <span>Add tag to selected icons:</span>

      <Input
        ref={saveInputRef}
        type="text"
        size="small"
        className={styles.tagInput}
        value={inputValue}
        onChange={handleInputChange}
        onPressEnter={addTag}
        disabled={!inputEnabled}
      />

      <Button size="small" onClick={addTag}>
        Add tag
      </Button>

      <AntFormItemExplain message={feedbackStatus.message} type={feedbackStatus.type} />
    </div>
  )
}
