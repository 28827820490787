import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import assetsReducer from '../features/assets/assetsSlice'
import usersReducer from '../features/users/usersSlice'
import preferencesReducer from '../features/preferences/preferencesSlice'
import authReducer from './auth/authSlice'

export const rootReducer = combineReducers({
  assets: assetsReducer,
  users: usersReducer,
  auth: authReducer,
  preferences: preferencesReducer,
})

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
