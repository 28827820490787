import * as firebaseAuth from 'firebase/auth'
import { message } from 'antd'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '../api/api'
import { IUser, userStatusListAdmins } from '../common-interfaces/User'
import { AppThunk, RootState } from '../store'
import auth from './firebaseAuthentication'
import { clearAuthenticationTokenFromLocalStorage } from './authentication'

import { RoutesEnum } from '../routes/types'

export interface AuthState {
  isValidatingToken: boolean
  isLoggedIn: boolean
  loggedInUser: IUser | null
  redirectTo: string | null
}

const initialState: AuthState = {
  isValidatingToken: true,
  isLoggedIn: false,
  loggedInUser: null,
  redirectTo: null,
}

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authTokenIsValid: (state, action: PayloadAction<{ user: IUser }>) => {
      state.loggedInUser = action.payload.user
      state.isLoggedIn = true
      state.isValidatingToken = false
    },
    authTokenIsInvalid: (state) => {
      state.loggedInUser = null
      state.isLoggedIn = initialState.isLoggedIn
      state.isValidatingToken = false
      state.redirectTo = RoutesEnum.SIGNIN
    },
    clearRedirect: (state) => {
      state.redirectTo = null
    },
  },
})

export const { authTokenIsValid, authTokenIsInvalid } = authSlice.actions

export const checkAuthorizationToken = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await api.get('/v4/users/me')
    if (userStatusListAdmins.includes(data.status)) {
      dispatch(authTokenIsValid({ user: data }))
    } else {
      dispatch(signOut())
      message.warn('Sign in not authorized.')
    }
  } catch (error: any) {
    if (error.response?.status === 404) {
      clearAuthenticationTokenFromLocalStorage()
      dispatch(checkAuthorizationToken())
    }
    dispatch(authTokenIsInvalid())
  }
}

export function signOut(): AppThunk {
  return async (dispatch) => {
    await firebaseAuth.signOut(auth)
    dispatch(authTokenIsInvalid())
  }
}

export const selectLoggedInUser = (state: RootState) => state.auth.loggedInUser
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn
export const selectIsValidatingToken = (state: RootState) => state.auth.isValidatingToken

export default authSlice.reducer
