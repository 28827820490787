import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { IndexingStatusEnum } from './types'
import styles from './ReindexResultButton.module.scss'
import {
  getIndexingResult,
  selectIsIndexing,
  selectIndexingResult,
  reIndexAssets,
} from '../preferencesSlice'
import useAxios from '../../../app/api/apiHook'

const ReindexResultButton: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [showIndexingResultModal, setShowIndexingResultModal] = useState(false)
  const indexingResult = useSelector(selectIndexingResult)
  const isIndexing = useSelector(selectIsIndexing)
  const location = useLocation()
  const openReindexingStatus =
    new URLSearchParams(location.search).get('openReindexingStatus') === 'true'

  const [{ data: responseLogs }, getIndexingLogs] = useAxios(
    {
      url: `/v4/open-search/index-logs`,
      method: 'GET',
    },
    { manual: true }
  )

  const loadIndexingResult = useCallback(async () => {
    dispatch(getIndexingResult())
    getIndexingLogs()
  }, [dispatch, getIndexingLogs])

  useEffect(() => {
    loadIndexingResult()

    if (openReindexingStatus) {
      loadIndexingResult()
      setShowIndexingResultModal(true)
    }
  }, [loadIndexingResult, openReindexingStatus])

  // If modal is open, we request the logs every few seconds
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined
    if (showIndexingResultModal) {
      intervalId = setInterval(() => {
        getIndexingLogs()
      }, 1000 * 5) // 5 seconds
    }
    return () => {
      if (intervalId) return clearInterval(intervalId)
      return undefined
    }
  }, [getIndexingLogs, showIndexingResultModal])

  const reindexPendingAssets = async () => {
    if (indexingResult) {
      dispatch(
        reIndexAssets(
          indexingResult.indexName,
          indexingResult.unindexedFamilies.map((family) => family.slug)
        )
      )
    }
  }

  return (
    <>
      {indexingResult.status !== IndexingStatusEnum.NONE && (
        <Button
          icon={isIndexing ? <SyncOutlined spin /> : <CheckCircleOutlined />}
          onClick={() => {
            loadIndexingResult()
            setShowIndexingResultModal(true)
          }}
        >
          {isIndexing ? 'Indexing icons...(Click to see logs)' : 'Check reindex status'}
        </Button>
      )}

      <Modal
        onCancel={() => setShowIndexingResultModal(false)}
        visible={showIndexingResultModal}
        title="Reindex status"
        footer={
          <Button
            onClick={reindexPendingAssets}
            loading={isIndexing}
            disabled={indexingResult.unindexedFamilies.length === 0}
          >
            Reindex pending families
          </Button>
        }
      >
        <div className={styles.ReindexResultDetails}>
          <span>Index name: {indexingResult.indexName}</span>

          <span>
            Status: <b>{indexingResult.status}</b>
          </span>

          {indexingResult.startedAt && (
            <span>Started: {format(new Date(indexingResult.startedAt), 'Pp')}</span>
          )}

          {indexingResult.finishedAt && (
            <span>Finished: {format(new Date(indexingResult.finishedAt), 'Pp')}</span>
          )}

          {indexingResult.totalIcons && indexingResult.totalIconsIndexed && (
            <span>
              Indexed icons: {indexingResult.totalIconsIndexed}/{indexingResult.totalIcons}
            </span>
          )}
        </div>
        {indexingResult.unindexedFamilies.length > 0 && (
          <div className={styles.ReindexResultUnindexedFamilies}>
            <Divider type="horizontal" />

            <h3>Families not indexed: </h3>

            <ul>
              {indexingResult.unindexedFamilies.map((family) => (
                <li key={family.name}>{family.name}</li>
              ))}
            </ul>
          </div>
        )}
        <br />
        Logs (updates every 5 seconds):
        {responseLogs && (
          <div className={styles.LogsSection}>
            {responseLogs.logs.map((log: string) => (
              <p key={log}>{log}</p>
            ))}
          </div>
        )}
      </Modal>
    </>
  )
}

export default ReindexResultButton
