import { Button, Form, message, Modal, Select } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../app/api/apiHook'
import * as assetsSlice from '../../assetsSlice'
import { Icon, Subcategory } from '../../types'

interface IconsMoveModalProps {
  icons: Icon[]
  onCancel(): void
  onSuccess(): void
}

interface FormProps {
  category: string
  subcategory: string
}

const IconsMoveModal: FunctionComponent<IconsMoveModalProps> = ({ icons, onCancel, onSuccess }) => {
  const [form] = Form.useForm()

  const [saving, setSaving] = useState(false)

  const categories = useSelector(assetsSlice.selectCategoriesByOrder)
  const defaultSubcategories = useSelector(assetsSlice.selectSubcategoriesByOrder)
  const selectedIcons = useSelector(assetsSlice.selectSelectedIcons)
  const defaultCategory = useSelector(assetsSlice.selectCategory)
  const defaultSubcategory = useSelector(assetsSlice.selectSubcategory)

  const [subcategories, setSubcategories] = useState<Subcategory[]>(defaultSubcategories)

  const [categoryHash, setCategoryHash] = useState(defaultCategory?.hash)

  const dispatch = useDispatch()

  const [, moveIcons] = useAxios(
    {
      url: '/v4/icons/move',
      method: 'PATCH',
    },
    { manual: true }
  )

  const [{ data: dataSubcategories, loading: loadingSubcategories, error: errorSubcategories }] =
    useAxios(
      {
        url: `/v4/categories/${categoryHash}/subcategories`,
        method: 'GET',
      },
      { manual: !categoryHash }
    )

  const handleChangeCategory = async (hash: string) => {
    setCategoryHash(hash)
  }

  const handleOnFinish = async (values: FormProps) => {
    try {
      setSaving(true)
      await moveIcons({
        data: { icons: selectedIcons.map((i) => i.hash), subcategoryHash: values.subcategory },
      })
      dispatch(assetsSlice.actions.removeIconsOnList(icons))
      setSaving(false)
      onSuccess()
      message.success(`${icons.length > 1 ? 'Icons' : 'Icon'} moved successfully!`)
    } catch (error) {
      setSaving(false)
      console.error(error)
      message.error(
        `The ${icons.length > 1 ? 'Icons were' : 'Icon was'}  not moved, please try again`
      )
    }
  }

  useEffect(() => {
    if (dataSubcategories) {
      setSubcategories(dataSubcategories)
      form.setFieldsValue({
        subcategory: dataSubcategories ? dataSubcategories[0].hash : '',
      })
    }
  }, [dataSubcategories, form])

  useEffect(() => {
    if (errorSubcategories) {
      message.error('Loading subcategories failed, please try again')
    }
  }, [errorSubcategories])

  return (
    <Modal
      closable={false}
      visible
      title={`Move assets from "${defaultSubcategory?.name}" to:`}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={saving} onClick={() => form.submit()}>
          Move
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="move-icon-form"
        onFinish={handleOnFinish}
        layout="vertical"
        requiredMark="optional"
        initialValues={{
          category: defaultCategory?.hash,
          subcategory: defaultSubcategory?.hash,
        }}
      >
        <Form.Item
          label="Category"
          name="category"
          rules={[
            {
              required: true,
              message: 'Please select a category!',
            },
          ]}
        >
          <Select<string, { value: string; children: string }>
            onChange={(e: string) => handleChangeCategory(e)}
            placeholder="Select Category"
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option: any) =>
              option!.children
                ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                : false
            }
          >
            {categories &&
              categories.map((f) => (
                <Select.Option key={f.hash} value={f.hash}>
                  {f.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Subcategory"
          name="subcategory"
          rules={[
            {
              required: true,
              message: 'Please select a subcategory!',
            },
          ]}
        >
          <Select<string, { value: string; children: string }>
            placeholder="Select Subcategory"
            showSearch
            disabled={loadingSubcategories}
            loading={loadingSubcategories}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
            }
          >
            {subcategories &&
              subcategories.map((f) => (
                <Select.Option key={f.hash} value={f.hash}>
                  {f.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default IconsMoveModal
