/* eslint-disable react/jsx-props-no-spreading */
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Space,
  Divider,
  Row,
  Col,
  Alert,
} from 'antd'
import React, { FunctionComponent, useState } from 'react'
import useAxios from '../../../../app/api/apiHook'
import handleError from '../../../../app/api/handleError'
import ColorScanBrokenAssets from '../color-scan-broken-assets/ColorScanBrokenAssets'
import ColorSquare from '../color-square/ColorSquare'
import ColorRepaintResultsProgress from './color-repaint-results/color-repaint-results-progress/ColorRepaintResultsProgress'
import ColorRepaintResults from './color-repaint-results/ColorRepaintResults'
import styles from './ColorScanForm.module.scss'
import ColorScanInvalidMultipleColorsAssets from '../color-scan-broken-assets/ColorScanInvalidMultipleColorsAssets'
import { ColorsScan, Family, RepaintResults } from '../../types'

const { confirm } = Modal

const validateHexColor = (_: any, value: string) => {
  if (value.startsWith('#') && value.length === 7) {
    return Promise.resolve()
  }
  return Promise.reject(new Error('Please add a valid hex color'))
}

interface ColorScanFormProps {
  onSubmit(): void
  onSubmitError(): void
  family: Family
  form: any
}

const ColorScanForm: FunctionComponent<ColorScanFormProps> = ({
  onSubmit,
  onSubmitError,
  family,
  form,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [scanResults, setScanResults] = useState<ColorsScan | null>(null)
  const [repaintResults, setRepaintResults] = useState<RepaintResults | null>(null)
  const [isSimulation, setIsSimulation] = useState(false)

  const [familyColorScheme, setFamilyColorScheme] = useState({})
  const [repaintError] = useState<string | undefined>()
  const [useOnlyOriginalColors, setUseOnlyOriginalColors] = useState(false)

  const [, scanColors] = useAxios(
    {
      url: `/v4/colors/${family?.slug}?useOnlyOriginalColors=${useOnlyOriginalColors}`,
      method: 'GET',
    },
    { manual: true }
  )

  const [{ loading: isLoadindRepaint }, updateColors] = useAxios(
    {
      url: `/v4/colors/${family?.slug}`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const handleSubmitRepaintSVGs = async (formValues: any) => {
    try {
      setIsLoading(true)
      onSubmit()
      if (isSimulation) {
        const { data } = await updateColors({
          data: {
            ...formValues,
          },
          params: { simulation: isSimulation },
        })
        setRepaintResults(data)
      } else {
        const { data } = await updateColors({
          data: {
            ...formValues,
          },
        })
        setRepaintResults(data)
      }
      setIsLoading(false)
    } catch (error) {
      onSubmitError()
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  const handleScanColors = async () => {
    try {
      setIsLoading(true)
      const response = await scanColors()
      setScanResults(response.data)
      form.setFieldsValue({
        familyColorScheme: response.data.colors,
      })
      setFamilyColorScheme(response.data.colors)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      onSubmitError()
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  const handleSimulateRepaint = () => {
    setIsSimulation(true)
    form.submit()
  }

  const showConfirmModal = () => {
    confirm({
      title: 'Are you sure you want to re-paint these assets?',
      icon: <ExclamationCircleOutlined />,
      content: `This action can impact many icons and can't be reverted, so make sure it's really needed`,
      okText: 'Yes, I want to re-paint icons',
      onOk() {
        setIsSimulation(false)
        form.submit()
      },
    })
  }

  const handleBack = () => {
    setRepaintResults(null)
    setIsSimulation(false)
  }

  if (isLoadindRepaint) return <ColorRepaintResultsProgress total={scanResults?.total || 0} />

  if (isLoading) return <div>Loading</div>

  if (repaintResults)
    return (
      <>
        {isSimulation && (
          <Button
            type="link"
            icon={<ArrowLeftOutlined />}
            htmlType="button"
            size="large"
            onClick={handleBack}
            title="Click here to go back to colors inputs"
          >
            Back to colors
          </Button>
        )}
        <ColorRepaintResults results={repaintResults} simulation={isSimulation} />
      </>
    )

  return (
    <>
      {!scanResults ? (
        <div className={styles.firstStep}>
          <p>
            The first step is to just scan the colors from {family.name} family <br />
            When the option <b>Use only original colors</b> is selected it will scan the colors
            considering only the original colors, discarding the previous changes. (nothing is saved
            on this first step, so feel free to use it).
          </p>
          <Button size="large" icon={<SearchOutlined />} onClick={() => handleScanColors()}>
            Start colors scan
          </Button>{' '}
          <Checkbox onChange={() => setUseOnlyOriginalColors(!useOnlyOriginalColors)}>
            Use only original colors
          </Checkbox>
        </div>
      ) : (
        <Form
          form={form}
          name="color-scan-form"
          onFinish={handleSubmitRepaintSVGs}
          layout="vertical"
          requiredMark="optional"
          initialValues={{ colors: {}, familyColorScheme: {} }}
          className={styles.root}
        >
          <ColorScanBrokenAssets brokenIcons={scanResults.brokenIcons} />
          <ColorScanInvalidMultipleColorsAssets
            invalidIcons={scanResults.invalidMultipleColorsIcons}
          />
          <div className={styles.resultsList}>
            {repaintError && (
              <Alert message={repaintError} type="error" className={styles.repaintError} />
            )}

            <Row className={styles.root}>
              <Col className={styles.right} span={24}>
                <h3>
                  Colors scheme:{' '}
                  <b>
                    mapping bettween the original colors of the SVGs and the current colors applied
                    to the SVGs. The original colors are on the left and the current colors are on
                    the right side.
                  </b>
                </h3>

                <ul>
                  {form.getFieldValue('familyColorScheme') &&
                    Object.keys(familyColorScheme) &&
                    Object.keys(familyColorScheme).map((c) => (
                      <li key={`${c}-${form.getFieldValue('familyColorScheme')[c]}`}>
                        <Space style={{ display: 'flex' }} align="baseline">
                          <span className={styles.originalColor}>{c}</span>
                          <ColorSquare color={c} /> <ArrowRightOutlined />
                          <ColorSquare color={form.getFieldValue('familyColorScheme')[c]} />
                          <Form.Item
                            name={['familyColorScheme', c]}
                            rules={[
                              {
                                required: true,
                                message: 'Please input a hex color!',
                              },
                              {
                                validator: validateHexColor,
                              },
                            ]}
                          >
                            <Input
                              className={styles.newColorInput}
                              onBlur={(e) => {
                                form.setFieldsValue({
                                  familyColorScheme: { [c]: e.currentTarget.value },
                                })
                                setFamilyColorScheme((prevState) => ({
                                  ...prevState,
                                  [c]: e.currentTarget.value,
                                }))
                              }}
                            />
                          </Form.Item>
                        </Space>
                      </li>
                    ))}
                </ul>
              </Col>
            </Row>

            <br />

            <Divider orientation="left">IMPORTANT:</Divider>

            <Form.Item name="uploadCloudinary" valuePropName="checked">
              <Checkbox>Upload modified images to Cloudinary</Checkbox>
            </Form.Item>
            <span className={styles.uploadCloudinaryWarning}>
              Selecting this checkbox will make the re-paint tool to run slower and will spend
              Cloudinary credits - so use it wisely. <br />
              We have to turn on the checkbox whenever the colors are really different. For small
              adjustments like adjusting the black color from #000001 to #000000 keep it turned off
              so we can save Cloudinary credits. But if we changed some color from red to blue for
              example, then we have to turn on the checkbox.
            </span>
            <Divider />

            <div className={styles.buttonsArea}>
              <Button
                htmlType="button"
                size="large"
                onClick={handleSimulateRepaint}
                title="With this option nothing will be saved in the database"
              >
                Simulate Re-paint SVG&apos;s
              </Button>
              <br />
              <Button
                type="primary"
                htmlType="button"
                size="large"
                onClick={showConfirmModal}
                title="With this option all changes to the SVG's will be saved in the database"
              >
                Re-paint SVG&apos;s
              </Button>
            </div>
          </div>
        </Form>
      )}
    </>
  )
}

export default ColorScanForm
