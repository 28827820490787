import { Button, Drawer, List, Tooltip, Typography, Collapse, Form, message } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { DeleteOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../app/api/apiHook'
import handleError from '../../../../app/api/handleError'
import DrawerHeader from '../../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../../assetsSlice'
import SVGComparison from '../../../image-engine/recompile-restore-drawer/SVGComparison'
import styles from './CategoryUploadDrawer.module.scss'
import { getAuthenticationToken } from '../../../../app/auth/authentication'
import envConfig from '../../../../app/config'
import UploadProgress from '../../family-drawer/family-pack-upload-drawer/family-pack-upload-progress/FamilyPackUploadProgress'
import {
  CategoryIconsUploadPreview,
  CategoryUpdateResult,
  ExistingIconPreview,
  NewIconPreview,
} from './types'
import { Category } from '../../types'

const { Text } = Typography

const CategoryUploadDrawer: FunctionComponent = () => {
  const drawerType = 'upload-category-assets'
  const drawer = useSelector(assetsSlice.selectDrawer)
  const category = drawer.data as Category
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [fileSize, setFileSize] = useState(0)
  const [saving, setSaving] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadToken, setUploadToken] = useState<string>('')
  const [uploadPreview, setUploadPreview] = useState<CategoryIconsUploadPreview[] | null>(null)
  const [uploadResult, setUploadResult] = useState<CategoryUpdateResult[] | null>(null)

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: drawerType, data: null }))
  }

  const [, postUploadChanges] = useAxios(
    {
      url: `/v4/categories/${category.hash}/complete-upload`,
      method: 'POST',
    },
    { manual: true, autoCancel: false }
  )

  const saveUploadChanges = async () => {
    const reviewedUploadChanges = uploadPreview?.map((preview) => ({
      subcategoryHash: preview.subcategoryHash,
      subcategoryName: preview.subcategoryName,
      changedIcons: preview.changedIcons.filter((icon) => !icon.ignore),
      newIcons: preview.newIcons.filter((icon) => !icon.ignore),
    }))

    try {
      setSaving(true)
      setUploadResult(null)
      const { data } = await postUploadChanges({
        data: { subcategories: reviewedUploadChanges },
      })
      setUploadPreview(null)
      setUploadResult(data)
      message.success('Upload changes saved!')
    } catch (error) {
      setErrorMessage(`Something went wrong: ${handleError(error)}`)
    } finally {
      setSaving(false)
    }
  }

  const handleUploadComplete = (data: CategoryIconsUploadPreview[]) => {
    setUploading(false)
    setUploadPreview(data)
    setErrorMessage(null)
  }

  const handleUploading = (size: number) => {
    setFileSize(size)
    setUploading(true)
    setUploadResult(null)
  }

  const handleUploadChanges = (info: UploadChangeParam<any>) => {
    if (info.file.status === 'error') {
      setFileSize(0)
      setUploading(false)
      setErrorMessage(info?.file?.response?.error || 'Something went wrong, please try again')
    }
    if (info.file.status === 'uploading') {
      handleUploading(info.file.size || 0)
    }
    if (info.file.status === 'done') {
      handleUploadComplete(info.file.response)
    }
  }

  const normalizeFileListFormat = (fileUploadEvent: UploadChangeParam): UploadFile[] =>
    fileUploadEvent.fileList

  const getUploadUrl = async () => {
    const token = await getAuthenticationToken()

    if (token) {
      setUploadToken(token)
    }

    return `${envConfig.API_URL}/v4/categories/${category.hash}/upload-preview`
  }

  const ignoreChange = (
    subcategoryPreview: CategoryIconsUploadPreview,
    ignoredIcon: ExistingIconPreview
  ) => {
    if (uploadPreview) {
      const uploadPreviewUpdated = uploadPreview.map((preview) =>
        preview.subcategoryName === subcategoryPreview.subcategoryName
          ? {
              ...subcategoryPreview,
              changedIcons: subcategoryPreview.changedIcons.map((changedIcon) =>
                changedIcon.name === ignoredIcon.name
                  ? { ...ignoredIcon, ignore: !ignoredIcon.ignore }
                  : changedIcon
              ),
            }
          : preview
      )

      setUploadPreview(uploadPreviewUpdated)
    }
  }

  const ignoreNewIcon = (
    subcategoryPreview: CategoryIconsUploadPreview,
    ignoredIcon: NewIconPreview
  ) => {
    if (uploadPreview) {
      const uploadPreviewUpdated = uploadPreview.map((preview) =>
        preview.subcategoryName === subcategoryPreview.subcategoryName
          ? {
              ...subcategoryPreview,
              newIcons: subcategoryPreview.newIcons.map((newIcon) =>
                newIcon.name === ignoredIcon.name
                  ? { ...ignoredIcon, ignore: !ignoredIcon.ignore }
                  : newIcon
              ),
            }
          : preview
      )

      setUploadPreview(uploadPreviewUpdated)
    }
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => handleCloseDrawer()}
        visible={drawer.type === drawerType && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={uploading}
          title="Replace SVG of multiple assets in this category"
          onClickCancel={() => handleCloseDrawer()}
          showDeleteButton={false}
        />

        <h4>
          Replace the SVG of existing icons and add new icons to this category by uploading a zip
          file containing the SVGs. The zipped folder must contain the following structure:
          <b>category/subcategory/icon.svg</b>.
        </h4>

        <Form>
          <Form.Item
            valuePropName="fileList"
            getValueFromEvent={normalizeFileListFormat}
            name="fileList"
          >
            {uploading && (
              <UploadProgress resultsReceived={uploadPreview !== null} fileSize={fileSize} />
            )}
            <>
              <div className={styles.draggerArea}>
                <Dragger
                  name="zipFile"
                  multiple={false}
                  action={getUploadUrl}
                  headers={{ Authorization: uploadToken }}
                  onChange={(info) => handleUploadChanges(info)}
                  showUploadList={false}
                  accept=".zip"
                >
                  <p className="ant-upload-drag-family">
                    <UploadOutlined width={18} />
                  </p>
                  <p className={styles.draggerText}>Click or drag file to this area to upload</p>
                  <p className={styles.draggerTextSmall}>File type accepted: .zip</p>
                </Dragger>
              </div>
            </>
          </Form.Item>
        </Form>

        <p>
          A preview of the changes that will be applied will be generated first, so you can check
          the changes before applying them.
        </p>

        <>
          {uploadPreview && uploadPreview.length > 0 && (
            <>
              <h3>Upload preview separated by subcategories</h3>

              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  pageSize: 10,
                  simple: true,
                }}
                dataSource={uploadPreview || []}
                renderItem={(subcategoryPreview) => (
                  <List.Item key={subcategoryPreview.subcategoryName} style={{ padding: '0px' }}>
                    <Collapse>
                      <Collapse.Panel
                        key={subcategoryPreview.subcategoryName}
                        header={`${subcategoryPreview.subcategoryName}`}
                      >
                        <Collapse>
                          <Collapse.Panel
                            key="Changed icons"
                            header={`Changed icons - ${subcategoryPreview.changedIcons.length}`}
                            style={{ backgroundColor: '#3db9f7' }}
                          >
                            {subcategoryPreview.changedIcons.length > 0 && (
                              <List
                                itemLayout="vertical"
                                size="large"
                                pagination={{
                                  pageSize: 10,
                                  simple: true,
                                }}
                                dataSource={subcategoryPreview.changedIcons}
                                renderItem={(icon) => (
                                  <List.Item key={icon.hash}>
                                    <div>
                                      <h4>
                                        <b className={icon.ignore ? styles.ignoredIcon : ''}>
                                          Image uploaded - {`${icon.name}`}{' '}
                                          {icon.ignore ? '- IGNORED' : ''}
                                        </b>
                                        {!icon.ignore && (
                                          <Button
                                            danger
                                            type="link"
                                            icon={<DeleteOutlined />}
                                            onClick={() => ignoreChange(subcategoryPreview, icon)}
                                          >
                                            Ignore this change
                                          </Button>
                                        )}
                                        {icon.ignore && (
                                          <Button
                                            type="link"
                                            icon={<PlusOutlined />}
                                            onClick={() => ignoreChange(subcategoryPreview, icon)}
                                          >
                                            Keep this change
                                          </Button>
                                        )}
                                      </h4>

                                      <SVGComparison
                                        firstSVG={icon.currentSvg}
                                        firstTitle="Image's current SVG"
                                        secondSVG={icon.newSvg}
                                        secondTitle="Image uploaded"
                                      />
                                    </div>
                                  </List.Item>
                                )}
                              />
                            )}
                          </Collapse.Panel>
                        </Collapse>

                        <Collapse>
                          <Collapse.Panel
                            key="New icons"
                            header={`New icons - ${subcategoryPreview.newIcons.length}`}
                            style={{ backgroundColor: '#5cb85c' }}
                          >
                            {subcategoryPreview.newIcons.length > 0 && (
                              <List
                                itemLayout="vertical"
                                size="large"
                                pagination={{
                                  pageSize: 10,
                                  simple: true,
                                }}
                                dataSource={subcategoryPreview.newIcons}
                                renderItem={(icon) => (
                                  <List.Item key={icon.name}>
                                    <div>
                                      <b className={icon.ignore ? styles.ignoredIcon : ''}>
                                        Image uploaded - {`${icon.name}`}{' '}
                                        {icon.ignore ? '- IGNORED' : ''}
                                      </b>

                                      {!icon.ignore && (
                                        <Button
                                          danger
                                          type="link"
                                          icon={<DeleteOutlined />}
                                          onClick={() => ignoreNewIcon(subcategoryPreview, icon)}
                                        >
                                          Ignore this new icon
                                        </Button>
                                      )}
                                      {icon.ignore && (
                                        <Button
                                          type="link"
                                          icon={<PlusOutlined />}
                                          onClick={() => ignoreNewIcon(subcategoryPreview, icon)}
                                        >
                                          Keep this new icon
                                        </Button>
                                      )}

                                      <img
                                        alt=""
                                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                                          icon.svg
                                        )}`}
                                        className={styles.image}
                                      />
                                      <Collapse ghost>
                                        <Collapse.Panel
                                          header="Image uploaded source code"
                                          key="current"
                                        >
                                          <Text code>{icon.svg}</Text>
                                        </Collapse.Panel>
                                      </Collapse>
                                    </div>
                                  </List.Item>
                                )}
                              />
                            )}
                          </Collapse.Panel>
                        </Collapse>

                        <Collapse>
                          <Collapse.Panel
                            key="Unchanged icons"
                            header={`Unchanged icons - ${subcategoryPreview.unchangedIcons.length}`}
                          >
                            {subcategoryPreview.unchangedIcons.length > 0 && (
                              <List
                                itemLayout="vertical"
                                size="large"
                                pagination={{
                                  pageSize: 10,
                                  simple: true,
                                }}
                                dataSource={subcategoryPreview.unchangedIcons}
                                renderItem={(icon) => (
                                  <List.Item key={icon.hash}>
                                    <div>
                                      <SVGComparison
                                        firstSVG={icon.currentSvg}
                                        firstTitle="Image's current SVG"
                                        secondSVG={icon.newSvg}
                                        secondTitle="Image uploaded"
                                      />
                                    </div>
                                  </List.Item>
                                )}
                              />
                            )}
                          </Collapse.Panel>
                        </Collapse>

                        {subcategoryPreview.brokenIcons.length > 0 && (
                          <Collapse>
                            <Collapse.Panel
                              key="Broken icons"
                              header={`Broken icons - ${subcategoryPreview.brokenIcons.length}`}
                              style={{ backgroundColor: '#FF9494' }}
                            >
                              <List
                                itemLayout="vertical"
                                size="large"
                                pagination={{
                                  pageSize: 10,
                                  simple: true,
                                }}
                                dataSource={subcategoryPreview.brokenIcons}
                                renderItem={(icon) => (
                                  <List.Item key={icon.name}>
                                    <div>
                                      <p style={{ fontWeight: 600 }}>Error: {icon.error}</p>
                                      <Text code>{icon.svg}</Text>
                                    </div>
                                  </List.Item>
                                )}
                              />
                            </Collapse.Panel>
                          </Collapse>
                        )}
                      </Collapse.Panel>
                    </Collapse>
                  </List.Item>
                )}
              />
            </>
          )}

          {uploadResult && uploadResult.length > 0 && (
            <>
              <h3>Upload result separated by subcategories</h3>

              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  pageSize: 10,
                  simple: true,
                }}
                dataSource={uploadResult || []}
                renderItem={(subcategoryResult) => (
                  <List.Item key={subcategoryResult.subcategory} style={{ padding: '0px' }}>
                    <Collapse>
                      <Collapse.Panel
                        key={subcategoryResult.subcategory}
                        header={`${subcategoryResult.subcategory}`}
                      >
                        {subcategoryResult.updatedIcons.length > 0 && (
                          <Collapse>
                            <Collapse.Panel
                              key="Changed icons"
                              header={`Changed icons - ${subcategoryResult.updatedIcons.length}`}
                              style={{ backgroundColor: '#3db9f7' }}
                            >
                              <List
                                itemLayout="vertical"
                                size="large"
                                pagination={{
                                  pageSize: 10,
                                  simple: true,
                                }}
                                dataSource={subcategoryResult.updatedIcons}
                                renderItem={({ icon, svg }) => (
                                  <List.Item key={icon}>
                                    <div>
                                      <h4>{icon}</h4>
                                      <img
                                        alt=""
                                        src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
                                        className={styles.image}
                                      />
                                    </div>
                                  </List.Item>
                                )}
                              />
                            </Collapse.Panel>
                          </Collapse>
                        )}

                        {subcategoryResult.createdIcons.length > 0 && (
                          <Collapse>
                            <Collapse.Panel
                              key="Created icons"
                              header={`Created icons - ${subcategoryResult.createdIcons.length}`}
                              style={{ backgroundColor: '#5cb85c' }}
                            >
                              <List
                                itemLayout="vertical"
                                size="large"
                                pagination={{
                                  pageSize: 10,
                                  simple: true,
                                }}
                                dataSource={subcategoryResult.createdIcons}
                                renderItem={({ icon, svg }) => (
                                  <List.Item key={icon}>
                                    <div>
                                      <h4>{icon}</h4>

                                      <img
                                        alt=""
                                        src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
                                        className={styles.image}
                                      />
                                    </div>
                                  </List.Item>
                                )}
                              />
                            </Collapse.Panel>
                          </Collapse>
                        )}

                        {subcategoryResult.errors.length > 0 && (
                          <Collapse>
                            <Collapse.Panel
                              key="Errors"
                              header={`Errors - ${subcategoryResult.errors.length}`}
                              style={{ backgroundColor: '#FF9494' }}
                            >
                              <List
                                itemLayout="vertical"
                                size="large"
                                pagination={{
                                  pageSize: 10,
                                  simple: true,
                                }}
                                dataSource={subcategoryResult.errors}
                                renderItem={({ icon, svg, error }) => (
                                  <List.Item key={icon}>
                                    <div>
                                      <p style={{ fontWeight: 600 }}>Error: {error}</p>
                                      <img
                                        alt=""
                                        src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
                                        className={styles.image}
                                      />
                                    </div>
                                  </List.Item>
                                )}
                              />
                            </Collapse.Panel>
                          </Collapse>
                        )}
                      </Collapse.Panel>
                    </Collapse>
                  </List.Item>
                )}
              />
            </>
          )}
        </>

        {uploadPreview && (
          <Tooltip title="Save the upload changes.">
            <Button
              htmlType="button"
              size="large"
              onClick={() => saveUploadChanges()}
              loading={saving}
            >
              Save upload changes
            </Button>
          </Tooltip>
        )}
        <br />
        {errorMessage && <Text type="danger">{errorMessage}</Text>}
      </Drawer>
    </>
  )
}

export default CategoryUploadDrawer
