import { Card, Col, Form, Input, Row, Select, Switch } from 'antd'
import { format } from 'date-fns'
import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import * as authSlice from '../../../../app/auth/authSlice'
import {
  BundleStatusEnum,
  UserStatus,
  userStatusListAdmins,
  userStatusListNonAdmins,
} from '../../../../app/common-interfaces/User'
import * as usersSlice from '../../usersSlice'
import AntFormItemExplain from '../../../../components/AntFormItemExplain/AntFormItemExplain'

const UserDetailsAccountCard: FunctionComponent = () => {
  const user = useSelector(usersSlice.selectUser)
  const loggedInUser = useSelector(authSlice.selectLoggedInUser)

  return (
    <Card title="Account" type="inner">
      <Form.Item
        label="Status"
        name="status"
        rules={[
          {
            required: true,
            message: 'Please input a status!',
          },
        ]}
      >
        <Select placeholder="Select Status">
          {userStatusListNonAdmins.map((s: string) => (
            <Select.Option key={s} value={s}>
              {s}
            </Select.Option>
          ))}

          {loggedInUser?.status === UserStatus.ADMIN && (
            <Select.OptGroup label="Super users">
              {userStatusListAdmins.map((s: string) => (
                <Select.Option key={s} value={s}>
                  {s}
                </Select.Option>
              ))}
            </Select.OptGroup>
          )}
        </Select>
      </Form.Item>

      <Form.Item
        label="Bundle status"
        name="bundleStatus"
        rules={[
          {
            required: !user?.hash,
            message: 'Please input a bundle status!',
          },
        ]}
      >
        <Select placeholder="Select Bundle Status" disabled={!!user?.hash}>
          {Object.values(BundleStatusEnum).map((s: string) => (
            <Select.Option key={s} value={s}>
              {s}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="First Name"
        name="firstname"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastname"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input an email!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Stripe Subscription Id" name="stripeSubscriptionId">
        <Input />
      </Form.Item>
      <Form.Item label="Stripe User Id" name="stripeId">
        <Input />
      </Form.Item>

      <Form.Item label="Is a user legacy?" name="isLegacy" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="Is a beta tester?" name="isBetaTester" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item
        label={
          <div>
            Downloads limit enabled
            <br />
            <AntFormItemExplain
              type="text"
              message="When disabled, both this user and all its invited members will be able to download as many assets as they want."
            />
          </div>
        }
        name="downloadsLimitEnabled"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Row>
        <Col>
          {user?.downloadsLimitPeriodResetsOn && (
            <p>
              Downloads limit period resets on:{' '}
              <b>{format(new Date(user.downloadsLimitPeriodResetsOn), 'Pp')}</b>. After this date
              the download limit will be set <b>1000</b> again.
            </p>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {user?.createdAt && <p>Account created on: {format(new Date(user.createdAt), 'Pp')}</p>}
          {user?.updatedAt && <p>Last update: {format(new Date(user.updatedAt), 'Pp')}</p>}
        </Col>
      </Row>
    </Card>
  )
}

export default UserDetailsAccountCard
