import { CloseOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import React, { FunctionComponent } from 'react'
import styles from './TagItem.module.scss'
import { ProductTypeEnum } from '../../assets/types'

interface TagItemProps {
  tag: string
  productType: ProductTypeEnum
  status: 'allow' | 'disallow'
  disabled: boolean
  onClick(productType: ProductTypeEnum, tag: string, status: 'allow' | 'disallow'): void
}

const TagItem: FunctionComponent<TagItemProps> = ({
  tag,
  productType,
  status,
  disabled,
  onClick,
}) => (
  <Tag key={`allowed-${productType}-${tag}`} className={styles.root}>
    <div className={styles.content}>
      {tag}
      <Button
        className={styles.closeButton}
        onClick={() => onClick(productType, tag, status)}
        size="small"
        shape="circle"
        type="text"
        icon={<CloseOutlined />}
        disabled={disabled}
      />
    </div>
  </Tag>
)

export default TagItem
